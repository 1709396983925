import branch1 from '../../../public/static/img/CH/winter/branch1.jpg'
import bridge1 from '../../../public/static/img/CH/winter/bridge1.jpg'
import bwfisheye1 from '../../../public/static/img/CH/winter/bwfisheye1.jpg'
import bwforest1 from '../../../public/static/img/CH/winter/bwforest1.jpg'
import bwforest2 from '../../../public/static/img/CH/winter/bwforest2.jpg'
import bwforest3 from '../../../public/static/img/CH/winter/bwforest3.jpg'
import bwforest4 from '../../../public/static/img/CH/winter/bwforest4.jpg'
import bwforest5 from '../../../public/static/img/CH/winter/bwforest5.jpg'
import bwforest6 from '../../../public/static/img/CH/winter/bwforest6.jpg'
import bwvista1 from '../../../public/static/img/CH/winter/bwvista1.jpg'
import bwvista2 from '../../../public/static/img/CH/winter/bwvista2.jpg'
import bwvista3 from '../../../public/static/img/CH/winter/bwvista3.jpg'
import dog1 from '../../../public/static/img/CH/winter/dog1.jpg'
import dunes1 from '../../../public/static/img/CH/winter/dunes1.jpg'
import dunes2 from '../../../public/static/img/CH/winter/dunes2.jpg'
import dunes3 from '../../../public/static/img/CH/winter/dunes3.jpg'
import dunes4 from '../../../public/static/img/CH/winter/dunes4.jpg'
import dunes5 from '../../../public/static/img/CH/winter/dunes5.jpg'
import dunes6 from '../../../public/static/img/CH/winter/dunes6.jpg'
import dunes7 from '../../../public/static/img/CH/winter/dunes7.jpg'
import dunes8 from '../../../public/static/img/CH/winter/dunes8.jpg'
import dunes9 from '../../../public/static/img/CH/winter/dunes9.jpg'
import dunes10 from '../../../public/static/img/CH/winter/dunes10.jpg'
import dunes11 from '../../../public/static/img/CH/winter/dunes11.jpg'
import fence1 from '../../../public/static/img/CH/winter/fence1.jpg'
import flames1 from '../../../public/static/img/CH/winter/flames1.jpg'
import flames2 from '../../../public/static/img/CH/winter/flames2.jpg'
import flames3 from '../../../public/static/img/CH/winter/flames3.jpg'
import flames4 from '../../../public/static/img/CH/winter/flames4.jpg'
import fog1 from '../../../public/static/img/CH/winter/fog1.jpg'
import fog2 from '../../../public/static/img/CH/winter/fog2.jpg'
import fog3 from '../../../public/static/img/CH/winter/fog3.jpg'
import forest1 from '../../../public/static/img/CH/winter/forest1.jpg'
import forest2 from '../../../public/static/img/CH/winter/forest2.jpg'
import forest3 from '../../../public/static/img/CH/winter/forest3.jpg'
import forest4 from '../../../public/static/img/CH/winter/forest4.jpg'
import forest5 from '../../../public/static/img/CH/winter/forest5.jpg'
import frutt1 from '../../../public/static/img/CH/winter/frutt1.jpg'
import frutt2 from '../../../public/static/img/CH/winter/frutt2.jpg'
import frutt3 from '../../../public/static/img/CH/winter/frutt3.jpg'
import frutt4 from '../../../public/static/img/CH/winter/frutt4.jpg'
import frutt5 from '../../../public/static/img/CH/winter/frutt5.jpg'
import hydrant1 from '../../../public/static/img/CH/winter/hydrant1.jpg'
import iceleaves1 from '../../../public/static/img/CH/winter/iceleaves1.jpg'
import iceleaves2 from '../../../public/static/img/CH/winter/iceleaves2.jpg'
import lake1 from '../../../public/static/img/CH/winter/lake1.jpg'
import logs1 from '../../../public/static/img/CH/winter/logs1.jpg'
import mountain1 from '../../../public/static/img/CH/winter/mountain1.jpg'
import mountain2 from '../../../public/static/img/CH/winter/mountain2.jpg'
import mountain3 from '../../../public/static/img/CH/winter/mountain3.jpg'
import path1 from '../../../public/static/img/CH/winter/path1.jpg'
import path2 from '../../../public/static/img/CH/winter/path2.jpg'
import path3 from '../../../public/static/img/CH/winter/path3.jpg'
import piste1 from '../../../public/static/img/CH/winter/piste1.jpg'
import piste2 from '../../../public/static/img/CH/winter/piste2.jpg'
import piste3 from '../../../public/static/img/CH/winter/piste3.jpg'
import piste4 from '../../../public/static/img/CH/winter/piste4.jpg'
import piste5 from '../../../public/static/img/CH/winter/piste5.jpg'
import rigi1 from '../../../public/static/img/CH/winter/rigi1.jpg'
import road1 from '../../../public/static/img/CH/winter/road1.jpg'
import snowhut1 from '../../../public/static/img/CH/winter/snowhut1.jpg'
import snowleaves1 from '../../../public/static/img/CH/winter/snowleaves1.jpg'
import snowroof1 from '../../../public/static/img/CH/winter/snowroof1.jpg'
import snowroof2 from '../../../public/static/img/CH/winter/snowroof2.jpg'
import snowroof3 from '../../../public/static/img/CH/winter/snowroof3.jpg'
import spire1 from '../../../public/static/img/CH/winter/spire1.jpg'
import spire2 from '../../../public/static/img/CH/winter/spire2.jpg'
import star1 from '../../../public/static/img/CH/winter/star1.jpg'
import statue1 from '../../../public/static/img/CH/winter/statue1.jpg'
import stump1 from '../../../public/static/img/CH/winter/stump1.jpg'
import temp1 from '../../../public/static/img/CH/winter/temp1.jpg'
import tracks1 from '../../../public/static/img/CH/winter/tracks1.jpg'
import tree1 from '../../../public/static/img/CH/winter/tree1.jpg'
import tree2 from '../../../public/static/img/CH/winter/tree2.jpg'
import tree3 from '../../../public/static/img/CH/winter/tree3.jpg'
import twigs1 from '../../../public/static/img/CH/winter/twigs1.jpg'
import village1 from '../../../public/static/img/CH/winter/village1.jpg'
import village2 from '../../../public/static/img/CH/winter/village2.jpg'
import village3 from '../../../public/static/img/CH/winter/village3.jpg'
import village4 from '../../../public/static/img/CH/winter/village4.jpg'
import vista1 from '../../../public/static/img/CH/winter/vista1.jpg'
import vista2 from '../../../public/static/img/CH/winter/vista2.jpg'
import vista3 from '../../../public/static/img/CH/winter/vista3.jpg'
import vista4 from '../../../public/static/img/CH/winter/vista4.jpg'
import vista5 from '../../../public/static/img/CH/winter/vista5.jpg'
import vista6 from '../../../public/static/img/CH/winter/vista6.jpg'

export const imagesCHWinter = {
  branch1,
  bridge1,
  bwfisheye1,
  bwforest1,
  bwforest2,
  bwforest3,
  bwforest4,
  bwforest5,
  bwforest6,
  bwvista1,
  bwvista2,
  bwvista3,
  dog1,
  dunes1,
  dunes2,
  dunes3,
  dunes4,
  dunes5,
  dunes6,
  dunes7,
  dunes8,
  dunes9,
  dunes10,
  dunes11,
  fence1,
  flames1,
  flames2,
  flames3,
  flames4,
  fog1,
  fog2,
  fog3,
  forest1,
  forest2,
  forest3,
  forest4,
  forest5,
  frutt1,
  frutt2,
  frutt3,
  frutt4,
  frutt5,
  hydrant1,
  iceleaves1,
  iceleaves2,
  lake1,
  logs1,
  mountain1,
  mountain2,
  mountain3,
  path1,
  path2,
  path3,
  piste1,
  piste2,
  piste3,
  piste4,
  piste5,
  rigi1,
  road1,
  snowhut1,
  snowleaves1,
  snowroof1,
  snowroof2,
  snowroof3,
  spire1,
  spire2,
  star1,
  statue1,
  stump1,
  temp1,
  tracks1,
  tree1,
  tree2,
  tree3,
  twigs1,
  village1,
  village2,
  village3,
  village4,
  vista1,
  vista2,
  vista3,
  vista4,
  vista5,
  vista6
}