import { imagesVegas } from '../../../components/US/West/imagesVegas'
import ImageModal from '../../../components/ImageModal'

function Vegas() {
  return (
    <div className="images">
      <ImageModal src={imagesVegas.aria1} alt="aria-1" height="200"/>
      <ImageModal src={imagesVegas.bellagio1} alt="bellagio-1" height="200"/>
      <ImageModal src={imagesVegas.bills1} alt="bills-1" height="200"/>
      <ImageModal src={imagesVegas.caesars1} alt="caesars-1" height="200"/>
      <ImageModal src={imagesVegas.caesars2} alt="caesars-2" height="200"/>
      <ImageModal src={imagesVegas.encore1} alt="encore-1" height="200"/>
      <ImageModal src={imagesVegas.flamingo1} alt="flamingo-1" height="200"/>
      <ImageModal src={imagesVegas.flamingo2} alt="flamingo-2" height="200"/>
      <ImageModal src={imagesVegas.fury1} alt="fury-1" height="200"/>
      <ImageModal src={imagesVegas.fury2} alt="fury-2" height="200"/>
      <ImageModal src={imagesVegas.mandarin1} alt="mandarin-1" height="200"/>
      <ImageModal src={imagesVegas.mandarin2} alt="mandarin-2" height="200"/>
      <ImageModal src={imagesVegas.mirage1} alt="mirage-1" height="200"/>
      <ImageModal src={imagesVegas.mustang1} alt="mustang-1" height="200"/>
      <ImageModal src={imagesVegas.palazzo1} alt="palazzo-1" height="200"/>
      <ImageModal src={imagesVegas.paris1} alt="paris-1" height="200"/>
      <ImageModal src={imagesVegas.paris2} alt="paris-2" height="200"/>
      <ImageModal src={imagesVegas.paris3} alt="paris-3" height="200"/>
      <ImageModal src={imagesVegas.paris4} alt="paris-4" height="200"/>
      <ImageModal src={imagesVegas.strat1} alt="strat-1" height="200"/>
      <ImageModal src={imagesVegas.strat2} alt="strat-2" height="200"/>
      <ImageModal src={imagesVegas.strip1} alt="strip-1" height="200"/>
      <ImageModal src={imagesVegas.strip2} alt="strip-2" height="200"/>
      <ImageModal src={imagesVegas.sunrise1} alt="sunrise-1" height="200"/>
      <ImageModal src={imagesVegas.sunrise2} alt="sunrise-2" height="200"/>
      <ImageModal src={imagesVegas.sunrise3} alt="sunrise-3" height="200"/>
      <ImageModal src={imagesVegas.sunrise4} alt="sunrise-4" height="200"/>
      <ImageModal src={imagesVegas.sunrise5} alt="sunrise-5" height="200"/>
      <ImageModal src={imagesVegas.treasureisland1} alt="treasureisland-1" height="200"/>
      <ImageModal src={imagesVegas.venetian1} alt="venetian-1" height="200"/>
    </div>
  )
}
export default Vegas