import { imagesGC } from '../../../components/US/West/imagesGC'
import ImageModal from '../../../components/ImageModal'

function GrandCanyon() {
  return (
    <div className="images">
      <ImageModal src={imagesGC.aerial1} alt="aerial-1" height="200"/>
      <ImageModal src={imagesGC.bison1} alt="bison-1" height="200"/>
      <ImageModal src={imagesGC.butte1} alt="butte-1" height="200"/>
      <ImageModal src={imagesGC.canyon1} alt="canyon-1" height="200"/>
      <ImageModal src={imagesGC.canyon2} alt="canyon-2" height="200"/>
      <ImageModal src={imagesGC.canyon3} alt="canyon-3" height="200"/>
      <ImageModal src={imagesGC.canyon4} alt="canyon-4" height="200"/>
      <ImageModal src={imagesGC.canyon5} alt="canyon-5" height="200"/>
      <ImageModal src={imagesGC.canyon6} alt="canyon-6" height="200"/>
      <ImageModal src={imagesGC.canyon7} alt="canyon-7" height="200"/>
      <ImageModal src={imagesGC.canyon8} alt="canyon-8" height="200"/>
      <ImageModal src={imagesGC.canyon9} alt="canyon-9" height="200"/>
      <ImageModal src={imagesGC.canyon10} alt="canyon-10" height="200"/>
      <ImageModal src={imagesGC.canyon11} alt="canyon-11" height="200"/>
      <ImageModal src={imagesGC.canyon12} alt="canyon-12" height="200"/>
      <ImageModal src={imagesGC.canyonbw1} alt="canyonbw-1" height="200"/>
      <ImageModal src={imagesGC.canyonwall1} alt="canyonwall-1" height="200"/>
      <ImageModal src={imagesGC.chopper1} alt="chopper-1" height="200"/>
      <ImageModal src={imagesGC.clouds1} alt="clouds-1" height="200"/>
      <ImageModal src={imagesGC.clouds2} alt="clouds-2" height="200"/>
      <ImageModal src={imagesGC.clouds3} alt="clouds-3" height="200"/>
      <ImageModal src={imagesGC.colorado1} alt="colorado-1" height="200"/>
      <ImageModal src={imagesGC.colorado2} alt="colorado-2" height="200"/>
      <ImageModal src={imagesGC.fisheyecanyon1} alt="fisheyecanyon-1" height="200"/>
      <ImageModal src={imagesGC.lake1} alt="lake-1" height="200"/>
      <ImageModal src={imagesGC.mine1} alt="mine-1" height="200"/>
      <ImageModal src={imagesGC.sandstone1} alt="sandstone-1" height="200"/>
      <ImageModal src={imagesGC.tree1} alt="tree-1" height="200"/>
      <ImageModal src={imagesGC.tree2} alt="tree-2" height="200"/>
      <ImageModal src={imagesGC.tree3} alt="tree-3" height="200"/>
    </div>
  )
}
export default GrandCanyon