import aqueduct1 from '../../../public/static/img/CH/lugano/aqueduct1.jpg'
import aqueduct2 from '../../../public/static/img/CH/lugano/aqueduct2.jpg'
import belltower1 from '../../../public/static/img/CH/lugano/belltower1.jpg'
import belltower2 from '../../../public/static/img/CH/lugano/belltower2.jpg'
import bwhouse1 from '../../../public/static/img/CH/lugano/bwhouse1.jpg'
import bwlake1 from '../../../public/static/img/CH/lugano/bwlake1.jpg'
import bwlake2 from '../../../public/static/img/CH/lugano/bwlake2.jpg'
import church1 from '../../../public/static/img/CH/lugano/church1.jpg'
import harbour1 from '../../../public/static/img/CH/lugano/harbour1.jpg'
import harbour2 from '../../../public/static/img/CH/lugano/harbour2.jpg'
import horizon1 from '../../../public/static/img/CH/lugano/horizon1.jpg'
import house1 from '../../../public/static/img/CH/lugano/house1.jpg'
import lake1 from '../../../public/static/img/CH/lugano/lake1.jpg'
import lake2 from '../../../public/static/img/CH/lugano/lake2.jpg'
import lake3 from '../../../public/static/img/CH/lugano/lake3.jpg'
import lake4 from '../../../public/static/img/CH/lugano/lake4.jpg'
import lake5 from '../../../public/static/img/CH/lugano/lake5.jpg'
import lake6 from '../../../public/static/img/CH/lugano/lake6.jpg'
import lake7 from '../../../public/static/img/CH/lugano/lake7.jpg'
import lake8 from '../../../public/static/img/CH/lugano/lake8.jpg'
import lake9 from '../../../public/static/img/CH/lugano/lake9.jpg'
import lake10 from '../../../public/static/img/CH/lugano/lake10.jpg'
import lake11 from '../../../public/static/img/CH/lugano/lake11.jpg'
import lake12 from '../../../public/static/img/CH/lugano/lake12.jpg'
import lake13 from '../../../public/static/img/CH/lugano/lake13.jpg'
import lake14 from '../../../public/static/img/CH/lugano/lake14.jpg'
import light1 from '../../../public/static/img/CH/lugano/light1.jpg'
import lugano1 from '../../../public/static/img/CH/lugano/lugano1.jpg'
import lugano2 from '../../../public/static/img/CH/lugano/lugano2.jpg'
import montebre1 from '../../../public/static/img/CH/lugano/montebre1.jpg'
import montebre2 from '../../../public/static/img/CH/lugano/montebre2.jpg'
import montebre3 from '../../../public/static/img/CH/lugano/montebre3.jpg'
import montebre4 from '../../../public/static/img/CH/lugano/montebre4.jpg'
import montebre5 from '../../../public/static/img/CH/lugano/montebre5.jpg'
import sculpture1 from '../../../public/static/img/CH/lugano/sculpture1.jpg'

export const imagesLugano = {
  aqueduct1,
  aqueduct2,
  belltower1,
  belltower2,
  bwhouse1,
  bwlake1,
  bwlake2,
  church1,
  harbour1,
  harbour2,
  horizon1,
  house1,
  lake1,
  lake2,
  lake3,
  lake4,
  lake5,
  lake6,
  lake7,
  lake8,
  lake9,
  lake10,
  lake11,
  lake12,
  lake13,
  lake14,
  light1,
  lugano1,
  lugano2,
  montebre1,
  montebre2,
  montebre3,
  montebre4,
  montebre5,
  sculpture1
}