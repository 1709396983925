import { imagesWildlife } from '../components/imagesWildlife'
import ImageModal from '../components/ImageModal'

function Wildlife() {
  return (
    <div className="images">
      <ImageModal src={imagesWildlife.antelope1} alt="antelope-1" height="200"/>
      <ImageModal src={imagesWildlife.bear1} alt="bear-1" height="200"/>
      <ImageModal src={imagesWildlife.cat1} alt="cat-1" height="200"/>
      <ImageModal src={imagesWildlife.cow1} alt="cow-1" height="200"/>
      <ImageModal src={imagesWildlife.cow2} alt="cow-2" height="200"/>
      <ImageModal src={imagesWildlife.deer1} alt="deer-1" height="200"/>
      <ImageModal src={imagesWildlife.duck1} alt="duck-1" height="200"/>
      <ImageModal src={imagesWildlife.duck2} alt="duck-2" height="200"/>
      <ImageModal src={imagesWildlife.finch1} alt="finch-1" height="200"/>
      <ImageModal src={imagesWildlife.finch2} alt="finch-2" height="200"/>
      <ImageModal src={imagesWildlife.finch3} alt="finch-3" height="200"/>
      <ImageModal src={imagesWildlife.gibbon1} alt="gibbon-1" height="200"/>
      <ImageModal src={imagesWildlife.gibbon2} alt="gibbon-2" height="200"/>
      <ImageModal src={imagesWildlife.goat1} alt="goat-1" height="200"/>
      <ImageModal src={imagesWildlife.goose1} alt="goose-1" height="200"/>
      <ImageModal src={imagesWildlife.goose2} alt="goose-2" height="200"/>
      <ImageModal src={imagesWildlife.goose3} alt="goose-3" height="200"/>
      <ImageModal src={imagesWildlife.goose4} alt="goose-4" height="200"/>
      <ImageModal src={imagesWildlife.gull1} alt="gull-1" height="200"/>
      <ImageModal src={imagesWildlife.koala1} alt="koala-1" height="200"/>
      <ImageModal src={imagesWildlife.leopard1} alt="leopard-1" height="200"/>
      <ImageModal src={imagesWildlife.leopard2} alt="leopard-2" height="200"/>
      <ImageModal src={imagesWildlife.swan1} alt="swan-1" height="200"/>
      <ImageModal src={imagesWildlife.lion1} alt="lion-1" height="200"/>
      <ImageModal src={imagesWildlife.lion2} alt="lion-2" height="200"/>
      <ImageModal src={imagesWildlife.peacock1} alt="peacock-1" height="200"/>
      <ImageModal src={imagesWildlife.peacock2} alt="peacock-2" height="200"/>
      <ImageModal src={imagesWildlife.peacock3} alt="peacock-3" height="200"/>
      <ImageModal src={imagesWildlife.redpanda1} alt="redpanda-1" height="200"/>
      <ImageModal src={imagesWildlife.hippo1} alt="hippo-1" height="200"/>
      <ImageModal src={imagesWildlife.penguin1} alt="penguin-1" height="200"/>
    </div>
  )
}
export default Wildlife