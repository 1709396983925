import aerial1 from '../../../public/static/img/Europe/barcelona/aerial1.jpg'
import aerial2 from '../../../public/static/img/Europe/barcelona/aerial2.jpg'
import candles1 from '../../../public/static/img/Europe/barcelona/candles1.jpg'
import cat1 from '../../../public/static/img/Europe/barcelona/cat1.jpg'
import cat2 from '../../../public/static/img/Europe/barcelona/cat2.jpg'
import cat3 from '../../../public/static/img/Europe/barcelona/cat3.jpg'
import cat4 from '../../../public/static/img/Europe/barcelona/cat4.jpg'
import church1 from '../../../public/static/img/Europe/barcelona/church1.jpg'
import door1 from '../../../public/static/img/Europe/barcelona/door1.jpg'
import familia1 from '../../../public/static/img/Europe/barcelona/familia1.jpg'
import familia2 from '../../../public/static/img/Europe/barcelona/familia2.jpg'
import flower1 from '../../../public/static/img/Europe/barcelona/flower1.jpg'
import fog1 from '../../../public/static/img/Europe/barcelona/fog1.jpg'
import gate1 from '../../../public/static/img/Europe/barcelona/gate1.jpg'
import gate2 from '../../../public/static/img/Europe/barcelona/gate2.jpg'
import horizon1 from '../../../public/static/img/Europe/barcelona/horizon1.jpg'
import horizon2 from '../../../public/static/img/Europe/barcelona/horizon2.jpg'
import path1 from '../../../public/static/img/Europe/barcelona/path1.jpg'
import path2 from '../../../public/static/img/Europe/barcelona/path2.jpg'
import sculpture1 from '../../../public/static/img/Europe/barcelona/sculpture1.jpg'
import sun1 from '../../../public/static/img/Europe/barcelona/sun1.jpg'
import tower1 from '../../../public/static/img/Europe/barcelona/tower1.jpg'
import towers1 from '../../../public/static/img/Europe/barcelona/towers1.jpg'
import towers2 from '../../../public/static/img/Europe/barcelona/towers2.jpg'
import tunnel1 from '../../../public/static/img/Europe/barcelona/tunnel1.jpg'
import turtle1 from '../../../public/static/img/Europe/barcelona/turtle1.jpg'
import waterfall1 from '../../../public/static/img/Europe/barcelona/waterfall1.jpg'

export const imagesBarcelona = {
  aerial1,
  aerial2,
  candles1,
  cat1,
  cat2,
  cat3,
  cat4,
  church1,
  door1,
  familia1,
  familia2,
  flower1,
  fog1,
  gate1,
  gate2,
  horizon1,
  horizon2,
  path1,
  path2,
  sculpture1,
  sun1,
  tower1,
  towers1,
  towers2,
  tunnel1,
  turtle1,
  waterfall1
}