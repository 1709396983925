import { imagesLugano } from '../../components/CH/imagesLugano'
import ImageModal from '../../components/ImageModal'

function Lugano() {
  return (
    <div className="images">
      <ImageModal src={imagesLugano.belltower1} alt="belltower-1" height="200"/>
      <ImageModal src={imagesLugano.belltower2} alt="belltower-2" height="200"/>
      <ImageModal src={imagesLugano.aqueduct1} alt="aqueduct-1" height="200"/>
      <ImageModal src={imagesLugano.bwhouse1} alt="bwhouse-1" height="200"/>
      <ImageModal src={imagesLugano.bwlake1} alt="bwlake-1" height="200"/>
      <ImageModal src={imagesLugano.bwlake2} alt="bwlake-2" height="200"/>
      <ImageModal src={imagesLugano.church1} alt="church-1" height="200"/>
      <ImageModal src={imagesLugano.harbour1} alt="harbour-1" height="200"/>
      <ImageModal src={imagesLugano.harbour2} alt="harbour-2" height="200"/>
      <ImageModal src={imagesLugano.horizon1} alt="horizon-1" height="200"/>
      <ImageModal src={imagesLugano.house1} alt="house-1" height="200"/>
      <ImageModal src={imagesLugano.montebre3} alt="montebre-3" height="200"/>
      <ImageModal src={imagesLugano.lake1} alt="lake-1" height="200"/>
      <ImageModal src={imagesLugano.lake2} alt="lake-2" height="200"/>
      <ImageModal src={imagesLugano.lake3} alt="lake-3" height="200"/>
      <ImageModal src={imagesLugano.lake4} alt="lake-4" height="200"/>
      <ImageModal src={imagesLugano.lake5} alt="lake-5" height="200"/>
      <ImageModal src={imagesLugano.lake6} alt="lake-6" height="200"/>
      <ImageModal src={imagesLugano.aqueduct2} alt="aqueduct-2" height="200"/>
      <ImageModal src={imagesLugano.lake7} alt="lake-7" height="200"/>
      <ImageModal src={imagesLugano.lake8} alt="lake-8" height="200"/>
      <ImageModal src={imagesLugano.lake9} alt="lake-9" height="200"/>
      <ImageModal src={imagesLugano.lake10} alt="lake-10" height="200"/>
      <ImageModal src={imagesLugano.lake11} alt="lake-11" height="200"/>
      <ImageModal src={imagesLugano.lake12} alt="lake-12" height="200"/>
      <ImageModal src={imagesLugano.lake13} alt="lake-13" height="200"/>
      <ImageModal src={imagesLugano.lake14} alt="lake-14" height="200"/>
      <ImageModal src={imagesLugano.light1} alt="light-1" height="200"/>
      <ImageModal src={imagesLugano.lugano1} alt="lugano-1" height="200"/>
      <ImageModal src={imagesLugano.lugano2} alt="lugano-2" height="200"/>
      <ImageModal src={imagesLugano.montebre1} alt="montebre-1" height="200"/>
      <ImageModal src={imagesLugano.montebre2} alt="montebre-2" height="200"/>
      <ImageModal src={imagesLugano.sculpture1} alt="sculpture-1" height="200"/>
      <ImageModal src={imagesLugano.montebre4} alt="montebre-4" height="200"/>
      <ImageModal src={imagesLugano.montebre5} alt="montebre-5" height="200"/>

    </div>
  )
}
export default Lugano