import badwater1 from '../../../../public/static/img/US/west/dv/badwater1.jpg'
import badwater2 from '../../../../public/static/img/US/west/dv/badwater2.jpg'
import badwater3 from '../../../../public/static/img/US/west/dv/badwater3.jpg'
import badwater4 from '../../../../public/static/img/US/west/dv/badwater4.jpg'
import benz1 from '../../../../public/static/img/US/west/dv/benz1.jpg'
import benz2 from '../../../../public/static/img/US/west/dv/benz2.jpg'
import bwzabriskie1 from '../../../../public/static/img/US/west/dv/bwzabriskie1.jpg'
import camaro1 from '../../../../public/static/img/US/west/dv/camaro1.jpg'
import camaro2 from '../../../../public/static/img/US/west/dv/camaro2.jpg'
import camaro3 from '../../../../public/static/img/US/west/dv/camaro3.jpg'
import clouds1 from '../../../../public/static/img/US/west/dv/clouds1.jpg'
import colourrocks1 from '../../../../public/static/img/US/west/dv/colourrocks1.jpg'
import colourrocks2 from '../../../../public/static/img/US/west/dv/colourrocks2.jpg'
import colourrocks3 from '../../../../public/static/img/US/west/dv/colourrocks3.jpg'
import dunes1 from '../../../../public/static/img/US/west/dv/dunes1.jpg'
import highway1 from '../../../../public/static/img/US/west/dv/highway1.jpg'
import highway2 from '../../../../public/static/img/US/west/dv/highway2.jpg'
import highway3 from '../../../../public/static/img/US/west/dv/highway3.jpg'
import horizon1 from '../../../../public/static/img/US/west/dv/horizon1.jpg'
import horizon2 from '../../../../public/static/img/US/west/dv/horizon2.jpg'
import spanishtrail1 from '../../../../public/static/img/US/west/dv/spanishtrail1.jpg'
import spanishtrail2 from '../../../../public/static/img/US/west/dv/spanishtrail2.jpg'
import spanishtrail3 from '../../../../public/static/img/US/west/dv/spanishtrail3.jpg'
import spanishtrail4 from '../../../../public/static/img/US/west/dv/spanishtrail4.jpg'
import temp1 from '../../../../public/static/img/US/west/dv/temp1.jpg'
import trail1 from '../../../../public/static/img/US/west/dv/trail1.jpg'
import zabriskie1 from '../../../../public/static/img/US/west/dv/zabriskie1.jpg'
import zabriskie2 from '../../../../public/static/img/US/west/dv/zabriskie2.jpg'
import zabriskie3 from '../../../../public/static/img/US/west/dv/zabriskie3.jpg'
import zabriskie4 from '../../../../public/static/img/US/west/dv/zabriskie4.jpg'
import zabriskie5 from '../../../../public/static/img/US/west/dv/zabriskie5.jpg'
import zabriskie6 from '../../../../public/static/img/US/west/dv/zabriskie6.jpg'
import zabriskie7 from '../../../../public/static/img/US/west/dv/zabriskie7.jpg'
import zabriskie8 from '../../../../public/static/img/US/west/dv/zabriskie8.jpg'
import zabriskie9 from '../../../../public/static/img/US/west/dv/zabriskie9.jpg'
import zabriskie10 from '../../../../public/static/img/US/west/dv/zabriskie10.jpg'

export const imagesDV = {
  badwater1,
  badwater2,
  badwater3,
  badwater4,
  benz1,
  benz2,
  bwzabriskie1,
  camaro1,
  camaro2,
  camaro3,
  clouds1,
  colourrocks1,
  colourrocks2,
  colourrocks3,
  dunes1,
  highway1,
  highway2,
  highway3,
  horizon1,
  horizon2,
  spanishtrail1,
  spanishtrail2,
  spanishtrail3,
  spanishtrail4,
  temp1,
  trail1,
  zabriskie1,
  zabriskie2,
  zabriskie3,
  zabriskie4,
  zabriskie5,
  zabriskie6,
  zabriskie7,
  zabriskie8,
  zabriskie9,
  zabriskie10
}