import antelope1 from '../../../../public/static/img/US/west/antelope/antelope1.jpg'
import antelope2 from '../../../../public/static/img/US/west/antelope/antelope2.jpg'
import antelope3 from '../../../../public/static/img/US/west/antelope/antelope3.jpg'
import antelope4 from '../../../../public/static/img/US/west/antelope/antelope4.jpg'
import bwantelope1 from '../../../../public/static/img/US/west/antelope/bwantelope1.jpg'
import bwantelope2 from '../../../../public/static/img/US/west/antelope/bwantelope2.jpg'
import bwantelope3 from '../../../../public/static/img/US/west/antelope/bwantelope3.jpg'
import bwantelope4 from '../../../../public/static/img/US/west/antelope/bwantelope4.jpg'
import bwantelope5 from '../../../../public/static/img/US/west/antelope/bwantelope5.jpg'
import bwantelope6 from '../../../../public/static/img/US/west/antelope/bwantelope6.jpg'
import bwantelope7 from '../../../../public/static/img/US/west/antelope/bwantelope7.jpg'
import bwantelope8 from '../../../../public/static/img/US/west/antelope/bwantelope8.jpg'
import bwantelope9 from '../../../../public/static/img/US/west/antelope/bwantelope9.jpg'
import bwantelope10 from '../../../../public/static/img/US/west/antelope/bwantelope10.jpg'
import bwantelope11 from '../../../../public/static/img/US/west/antelope/bwantelope11.jpg'

export const imagesAntelope = {
  antelope1,
  antelope2,
  antelope3,
  antelope4,
  bwantelope1,
  bwantelope2,
  bwantelope3,
  bwantelope4,
  bwantelope5,
  bwantelope6,
  bwantelope7,
  bwantelope8,
  bwantelope9,
  bwantelope10,
  bwantelope11
}