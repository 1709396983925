import { imagesDC } from '../../../components/US/NorthEast/imagesDC'
import ImageModal from '../../../components/ImageModal'

function DC() {
  return (
    <div className="images">
      <ImageModal src={imagesDC.archives1} alt="archives-1" height="200"/>
      <ImageModal src={imagesDC.badge1} alt="badge-1" height="200"/>
      <ImageModal src={imagesDC.columns1} alt="columns-1" height="200"/>
      <ImageModal src={imagesDC.dodge1} alt="dodge-1" height="200"/>
      <ImageModal src={imagesDC.eagle1} alt="eagle-1" height="200"/>
      <ImageModal src={imagesDC.freedom1} alt="freedom-1" height="200"/>
      <ImageModal src={imagesDC.lockheed1} alt="lockheed-1" height="200"/>
      <ImageModal src={imagesDC.memorial1} alt="memorial-1" height="200"/>
      <ImageModal src={imagesDC.memorial2} alt="memorial-2" height="200"/>
      <ImageModal src={imagesDC.mobil1} alt="mobil-1" height="200"/>
      <ImageModal src={imagesDC.monument1} alt="monument-1" height="200"/>
      <ImageModal src={imagesDC.patch1} alt="patch-1" height="200"/>
      <ImageModal src={imagesDC.peterbilt1} alt="peterbilt-1" height="200"/>
      <ImageModal src={imagesDC.pinkflower1} alt="pinkflower-1" height="200"/>
      <ImageModal src={imagesDC.poster1} alt="poster-1" height="200"/>
      <ImageModal src={imagesDC.poster2} alt="poster-2" height="200"/>
      <ImageModal src={imagesDC.pump1} alt="pump-1" height="200"/>
      <ImageModal src={imagesDC.rails1} alt="rails-1" height="200"/>
      <ImageModal src={imagesDC.redflower1} alt="redflower-1" height="200"/>
      <ImageModal src={imagesDC.redleaves1} alt="redleaves-1" height="200"/>
      <ImageModal src={imagesDC.redyellowflower1} alt="redyellowflower-1" height="200"/>
      <ImageModal src={imagesDC.service1} alt="service-1" height="200"/>
      <ImageModal src={imagesDC.sigil1} alt="sigil-1" height="200"/>
      <ImageModal src={imagesDC.statue1} alt="statue-1" height="200"/>
      <ImageModal src={imagesDC.statue2} alt="statue-2" height="200"/>
      <ImageModal src={imagesDC.statue3} alt="statue-3" height="200"/>
      <ImageModal src={imagesDC.statue4} alt="statue-4" height="200"/>
      <ImageModal src={imagesDC.statue5} alt="statue-5" height="200"/>
      <ImageModal src={imagesDC.statue6} alt="statue-6" height="200"/>
      <ImageModal src={imagesDC.warrenite1} alt="warrenite-1" height="200"/>
      <ImageModal src={imagesDC.whitehouse1} alt="whitehouse-1" height="200"/>      
    </div>
  )
}
export default DC