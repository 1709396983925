import chimney1 from '../../../../public/static/img/US/northeast/philly/chimney1.jpg'
import corner1 from '../../../../public/static/img/US/northeast/philly/corner1.jpg'
import drexel1 from '../../../../public/static/img/US/northeast/philly/drexel1.jpg'
import fireescape1 from '../../../../public/static/img/US/northeast/philly/fireescape1.jpg'
import fmc1 from '../../../../public/static/img/US/northeast/philly/fmc1.jpg'
import fmc2 from '../../../../public/static/img/US/northeast/philly/fmc2.jpg'
import mural1 from '../../../../public/static/img/US/northeast/philly/mural1.jpg'
import mural2 from '../../../../public/static/img/US/northeast/philly/mural2.jpg'
import mural3 from '../../../../public/static/img/US/northeast/philly/mural3.jpg'
import mural4 from '../../../../public/static/img/US/northeast/philly/mural4.jpg'
import mural5 from '../../../../public/static/img/US/northeast/philly/mural5.jpg'
import mural6 from '../../../../public/static/img/US/northeast/philly/mural6.jpg'
import mural7 from '../../../../public/static/img/US/northeast/philly/mural7.jpg'
import mural8 from '../../../../public/static/img/US/northeast/philly/mural8.jpg'
import mural9 from '../../../../public/static/img/US/northeast/philly/mural9.jpg'
import mural10 from '../../../../public/static/img/US/northeast/philly/mural10.jpg'
import mural11 from '../../../../public/static/img/US/northeast/philly/mural11.jpg'
import mural12 from '../../../../public/static/img/US/northeast/philly/mural12.jpg'
import mural13 from '../../../../public/static/img/US/northeast/philly/mural13.jpg'
import mural14 from '../../../../public/static/img/US/northeast/philly/mural14.jpg'
import mural15 from '../../../../public/static/img/US/northeast/philly/mural15.jpg'
import muraltower1 from '../../../../public/static/img/US/northeast/philly/muraltower1.jpg'
import muraltower2 from '../../../../public/static/img/US/northeast/philly/muraltower2.jpg'
import noparking1 from '../../../../public/static/img/US/northeast/philly/noparking1.jpg'
import phillyskyline1 from '../../../../public/static/img/US/northeast/philly/phillyskyline1.jpg'
import phillyskyline2 from '../../../../public/static/img/US/northeast/philly/phillyskyline2.jpg'
import postoffice1 from '../../../../public/static/img/US/northeast/philly/postoffice1.jpg'
import railcars1 from '../../../../public/static/img/US/northeast/philly/railcars1.jpg'
import rivets1 from '../../../../public/static/img/US/northeast/philly/rivets1.jpg'
import senate1 from '../../../../public/static/img/US/northeast/philly/senate1.jpg'
import senate2 from '../../../../public/static/img/US/northeast/philly/senate2.jpg'
import ships1 from '../../../../public/static/img/US/northeast/philly/ships1.jpg'
import society1 from '../../../../public/static/img/US/northeast/philly/society1.jpg'
import spruce1 from '../../../../public/static/img/US/northeast/philly/spruce1.jpg'
import station1 from '../../../../public/static/img/US/northeast/philly/station1.jpg'
import station2 from '../../../../public/static/img/US/northeast/philly/station2.jpg'
import stonefishes1 from '../../../../public/static/img/US/northeast/philly/stonefishes1.jpg'
import trashcan1 from '../../../../public/static/img/US/northeast/philly/trashcan1.jpg'
import windows1 from '../../../../public/static/img/US/northeast/philly/windows1.jpg'
import windows2 from '../../../../public/static/img/US/northeast/philly/windows2.jpg'
import windows3 from '../../../../public/static/img/US/northeast/philly/windows3.jpg'
import windows4 from '../../../../public/static/img/US/northeast/philly/windows4.jpg'

export const imagesPhilly = {
  chimney1,
  corner1,
  drexel1,
  fireescape1,
  fmc1,
  fmc2,
  mural1,
  mural2,
  mural3,
  mural4,
  mural5,
  mural6,
  mural7,
  mural8,
  mural9,
  mural10,
  mural11,
  mural12,
  mural13,
  mural14,
  mural15,
  muraltower1,
  muraltower2,
  noparking1,
  phillyskyline1,
  phillyskyline2,
  postoffice1,
  railcars1,
  rivets1,
  senate1,
  senate2,
  ships1,
  society1,
  spruce1,
  station1,
  station2,
  stonefishes1,
  trashcan1,
  windows1,
  windows2,
  windows3,
  windows4
}