import Modal from 'react-modal'
import { useState } from 'react'

const modalStyles = {
  content: {
    maxWidth: '1200px',
    // maxHeight: '1200px',
    height: '1200px',
    objectFit: 'contain',
    // overflow: 'hidden',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
  },
}

const containerStyles = {
  content: {
    // top: '100px',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // verticalAlign: 'middle',
    maxWidth: '1200px',
    maxHeight: '1200px',
    // border: '1px solid black',
    border: '0px',
    // width: 'min-content', 
    width: 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    position: 'fixed',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto'
  }
}

const imageFrameStyles = {
  boxShadow: '3px 3px 6px rgba(0, 0, 0, .15)',
  background: '#ee0',
  margin: '20px auto'
}

const imageStyles = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  margin: 'auto',
  boxShadow: '6px 6px 12px rgba(0, 0, 0, .15)',
  borderRadius: '10px',
  // maxWidth: '1200px',
  // maxHeight: '1200px',
  // width: '100%',
  // height: '100%',
  maxWidth: '100%',
  maxHeight: '100%'
}

Modal.setAppElement('#root')

function ImageModal({src, alt, height}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  // Open / close modal  
  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  return (
    <>
      <div className="image">
        <img src={src} alt={alt} height={height} onClick={openModal} />
      </div>
      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal} 
        portalClassName="modal"
        style={containerStyles} 
        contentLabel='Enlarge Image'
      >
        {/* <h2>Add Note</h2> */}
        {/* <div className="imageZoom" style={imageFrameStyles}> */}
        <img src={src} alt={alt} style={imageStyles}/>
        {/* </div> */}
        <button 
          className="btn-close" 
          onClick={closeModal}
        >
          Close Image
        </button>
      </Modal>
    </>
  )
}
export default ImageModal