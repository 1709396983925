import bwglacier1 from '../../../public/static/img/CH/summer/mountains/bwglacier1.jpg'
import bwmountain1 from '../../../public/static/img/CH/summer/mountains/bwmountain1.jpg'
import bwmountain2 from '../../../public/static/img/CH/summer/mountains/bwmountain2.jpg'
import eggishorn1 from '../../../public/static/img/CH/summer/mountains/eggishorn1.jpg'
import furka1 from '../../../public/static/img/CH/summer/mountains/furka1.jpg'
import glacier1 from '../../../public/static/img/CH/summer/mountains/glacier1.jpg'
import glacier2 from '../../../public/static/img/CH/summer/mountains/glacier2.jpg'
import glacier3 from '../../../public/static/img/CH/summer/mountains/glacier3.jpg'
import glacier4 from '../../../public/static/img/CH/summer/mountains/glacier4.jpg'
import glacier5 from '../../../public/static/img/CH/summer/mountains/glacier5.jpg'
import glacier6 from '../../../public/static/img/CH/summer/mountains/glacier6.jpg'
import grimsel1 from '../../../public/static/img/CH/summer/mountains/grimsel1.jpg'
import grimsel2 from '../../../public/static/img/CH/summer/mountains/grimsel2.jpg'
import huts1 from '../../../public/static/img/CH/summer/mountains/huts1.jpg'
import pass1 from '../../../public/static/img/CH/summer/mountains/pass1.jpg'
import pass2 from '../../../public/static/img/CH/summer/mountains/pass2.jpg'
import pass3 from '../../../public/static/img/CH/summer/mountains/pass3.jpg'
import pass4 from '../../../public/static/img/CH/summer/mountains/pass4.jpg'
import pass5 from '../../../public/static/img/CH/summer/mountains/pass5.jpg'
import pass6 from '../../../public/static/img/CH/summer/mountains/pass6.jpg'
import pass7 from '../../../public/static/img/CH/summer/mountains/pass7.jpg'
import pass8 from '../../../public/static/img/CH/summer/mountains/pass8.jpg'
import pass9 from '../../../public/static/img/CH/summer/mountains/pass9.jpg'
import pass10 from '../../../public/static/img/CH/summer/mountains/pass10.jpg'
import pass11 from '../../../public/static/img/CH/summer/mountains/pass11.jpg'
import pass12 from '../../../public/static/img/CH/summer/mountains/pass12.jpg'
import vista1 from '../../../public/static/img/CH/summer/mountains/vista1.jpg'
import vista2 from '../../../public/static/img/CH/summer/mountains/vista2.jpg'
import vista3 from '../../../public/static/img/CH/summer/mountains/vista3.jpg'
import vista4 from '../../../public/static/img/CH/summer/mountains/vista4.jpg'
import vista5 from '../../../public/static/img/CH/summer/mountains/vista5.jpg'
import vista6 from '../../../public/static/img/CH/summer/mountains/vista6.jpg'
import vista7 from '../../../public/static/img/CH/summer/mountains/vista7.jpg'
import vista8 from '../../../public/static/img/CH/summer/mountains/vista8.jpg'
import vista9 from '../../../public/static/img/CH/summer/mountains/vista9.jpg'
import vista10 from '../../../public/static/img/CH/summer/mountains/vista10.jpg'
import vista11 from '../../../public/static/img/CH/summer/mountains/vista11.jpg'
import vista12 from '../../../public/static/img/CH/summer/mountains/vista12.jpg'
import vista13 from '../../../public/static/img/CH/summer/mountains/vista13.jpg'
import vista14 from '../../../public/static/img/CH/summer/mountains/vista14.jpg'
import vista15 from '../../../public/static/img/CH/summer/mountains/vista15.jpg'
import vista16 from '../../../public/static/img/CH/summer/mountains/vista16.jpg'
import vista17 from '../../../public/static/img/CH/summer/mountains/vista17.jpg'
import vista18 from '../../../public/static/img/CH/summer/mountains/vista18.jpg'
import vista19 from '../../../public/static/img/CH/summer/mountains/vista19.jpg'
import vista20 from '../../../public/static/img/CH/summer/mountains/vista20.jpg'
import vista21 from '../../../public/static/img/CH/summer/mountains/vista21.jpg'
import vista22 from '../../../public/static/img/CH/summer/mountains/vista22.jpg'
import vista23 from '../../../public/static/img/CH/summer/mountains/vista23.jpg'
import vista24 from '../../../public/static/img/CH/summer/mountains/vista24.jpg'
import vista25 from '../../../public/static/img/CH/summer/mountains/vista25.jpg'

export const imagesMountains = {
  bwglacier1,
  bwmountain1,
  bwmountain2,
  eggishorn1,
  furka1,
  glacier1,
  glacier2,
  glacier3,
  glacier4,
  glacier5,
  glacier6,
  grimsel1,
  grimsel2,
  huts1,
  pass1,
  pass2,
  pass3,
  pass4,
  pass5,
  pass6,
  pass7,
  pass8,
  pass9,
  pass10,
  pass11,
  pass12,
  vista1,
  vista2,
  vista3,
  vista4,
  vista5,
  vista6,
  vista7,
  vista8,
  vista9,
  vista10,
  vista11,
  vista12,
  vista13,
  vista14,
  vista15,
  vista16,
  vista17,
  vista18,
  vista19,
  vista20,
  vista21,
  vista22,
  vista23,
  vista24,
  vista25
}