import bwforest3 from '../../../public/static/img/CH/winter/bwforest3.jpg'
import huts1 from '../../../public/static/img/CH/engelberg/huts1.jpg'
import montebre3 from '../../../public/static/img/CH/lugano/montebre3.jpg'
import peak2 from '../../../public/static/img/CH/matterhorn/peak2.jpg'
import mountain9 from '../../../public/static/img/CH/guarda/mountain9.jpg'
import vista25 from '../../../public/static/img/CH/summer/mountains/vista25.jpg'

import Image from '../../components/Image'

import { Link } from "react-router-dom"

function CH() {
  return (
    <div className='images imagesHome'>   
      <div className="linkImage">
        <Link to='/engelberg'>
          <Image src={huts1} alt="engelberg" height="200"/>
          <p className="imageLabel">Engelberg</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/lugano'>
          <Image src={montebre3} alt="lugano" height="200"/>
          <p className="imageLabel">Lugano</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/guarda'>
          <Image src={mountain9} alt="guarda" height="200"/>
          <p className="imageLabel">Guarda</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/matterhorn'>
          <Image src={peak2} alt="matterhorn" height="200"/>
          <p className="imageLabel">Matterhorn</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/mountains'>
          <Image src={vista25} alt="mountains" height="200"/>
          <p className="imageLabel">Mountains</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/chwinter'>
          <Image src={bwforest3} alt="chwinter" height="200"/>
          <p className="imageLabel">Winter</p>
        </Link>
      </div>   
    </div>
  )
}
export default CH