import aerial1 from '../../../public/static/img/Europe/malta/aerial1.jpg'
import alley1 from '../../../public/static/img/Europe/malta/alley1.jpg'
import arch1 from '../../../public/static/img/Europe/malta/arch1.jpg'
import balcony1 from '../../../public/static/img/Europe/malta/balcony1.jpg'
import bell1 from '../../../public/static/img/Europe/malta/bell1.jpg'
import crest1 from '../../../public/static/img/Europe/malta/crest1.jpg'
import crest2 from '../../../public/static/img/Europe/malta/crest2.jpg'
import dive1 from '../../../public/static/img/Europe/malta/dive1.jpg'
import dive2 from '../../../public/static/img/Europe/malta/dive2.jpg'
import dive3 from '../../../public/static/img/Europe/malta/dive3.jpg'
import dive4 from '../../../public/static/img/Europe/malta/dive4.jpg'
import fireworks1 from '../../../public/static/img/Europe/malta/fireworks1.jpg'
import flower1 from '../../../public/static/img/Europe/malta/flower1.jpg'
import harbour1 from '../../../public/static/img/Europe/malta/harbour1.jpg'
import harbour2 from '../../../public/static/img/Europe/malta/harbour2.jpg'
import harbour3 from '../../../public/static/img/Europe/malta/harbour3.jpg'
import harbour4 from '../../../public/static/img/Europe/malta/harbour4.jpg'
import harbour5 from '../../../public/static/img/Europe/malta/harbour5.jpg'
import illuminations1 from '../../../public/static/img/Europe/malta/illuminations1.jpg'
import knight1 from '../../../public/static/img/Europe/malta/knight1.jpg'
import lion1 from '../../../public/static/img/Europe/malta/lion1.jpg'
import lion2 from '../../../public/static/img/Europe/malta/lion2.jpg'
import ocean1 from '../../../public/static/img/Europe/malta/ocean1.jpg'
import statue1 from '../../../public/static/img/Europe/malta/statue1.jpg'
import sunrise1 from '../../../public/static/img/Europe/malta/sunrise1.jpg'
import sunset1 from '../../../public/static/img/Europe/malta/sunset1.jpg'
import tower1 from '../../../public/static/img/Europe/malta/tower1.jpg'
import trails1 from '../../../public/static/img/Europe/malta/trails1.jpg'
import window1 from '../../../public/static/img/Europe/malta/window1.jpg'

export const imagesMalta = {
  aerial1,
  alley1,
  arch1,
  balcony1,
  bell1,
  crest1,
  crest2,
  dive1,
  dive2,
  dive3,
  dive4,
  fireworks1,
  flower1,
  harbour1,
  harbour2,
  harbour3,
  harbour4,
  harbour5,
  illuminations1,
  knight1,
  lion1,
  lion2,
  ocean1,
  statue1,
  sunrise1,
  sunset1,
  tower1,
  trails1,
  window1
}