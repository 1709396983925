import { imagesCHWinter } from '../../components/CH/imagesCHWinter'
import ImageModal from '../../components/ImageModal'

function CHWinter() {
  return (
    <div className="images">
      <ImageModal src={imagesCHWinter.branch1} alt="branch-1" height="200"/>
      <ImageModal src={imagesCHWinter.bridge1} alt="bridge-1" height="200"/>
      <ImageModal src={imagesCHWinter.bwfisheye1} alt="bwfisheye-1" height="200"/>
      <ImageModal src={imagesCHWinter.bwforest1} alt="bwforest-1" height="200"/>
      <ImageModal src={imagesCHWinter.bwforest2} alt="bwforest-2" height="200"/>
      <ImageModal src={imagesCHWinter.bwforest3} alt="bwforest-3" height="200"/>
      <ImageModal src={imagesCHWinter.bwforest4} alt="bwforest-4" height="200"/>
      <ImageModal src={imagesCHWinter.bwforest5} alt="bwforest-5" height="200"/>
      <ImageModal src={imagesCHWinter.bwforest6} alt="bwforest-6" height="200"/>
      <ImageModal src={imagesCHWinter.bwvista1} alt="bwvista-1" height="200"/>
      <ImageModal src={imagesCHWinter.bwvista2} alt="bwvista-2" height="200"/>
      <ImageModal src={imagesCHWinter.bwvista3} alt="bwvista-3" height="200"/>
      <ImageModal src={imagesCHWinter.dog1} alt="dog-1" height="200"/>
      <ImageModal src={imagesCHWinter.dunes1} alt="dunes-1" height="200"/>
      <ImageModal src={imagesCHWinter.dunes2} alt="dunes-2" height="200"/>
      <ImageModal src={imagesCHWinter.dunes3} alt="dunes-3" height="200"/>
      <ImageModal src={imagesCHWinter.dunes4} alt="dunes-4" height="200"/>
      <ImageModal src={imagesCHWinter.dunes5} alt="dunes-5" height="200"/>
      <ImageModal src={imagesCHWinter.dunes6} alt="dunes-6" height="200"/>
      <ImageModal src={imagesCHWinter.dunes7} alt="dunes-7" height="200"/>
      <ImageModal src={imagesCHWinter.dunes8} alt="dunes-8" height="200"/>
      <ImageModal src={imagesCHWinter.dunes9} alt="dunes-9" height="200"/>
      <ImageModal src={imagesCHWinter.dunes10} alt="dunes-10" height="200"/>
      <ImageModal src={imagesCHWinter.dunes11} alt="dunes-11" height="200"/>
      <ImageModal src={imagesCHWinter.fence1} alt="fence-1" height="200"/>
      <ImageModal src={imagesCHWinter.flames1} alt="flames-1" height="200"/>
      <ImageModal src={imagesCHWinter.flames2} alt="flames-2" height="200"/>
      <ImageModal src={imagesCHWinter.flames3} alt="flames-3" height="200"/>
      <ImageModal src={imagesCHWinter.flames4} alt="flames-4" height="200"/>
      <ImageModal src={imagesCHWinter.fog1} alt="fog-1" height="200"/>
      <ImageModal src={imagesCHWinter.fog2} alt="fog-2" height="200"/>
      <ImageModal src={imagesCHWinter.fog3} alt="fog-3" height="200"/>
      <ImageModal src={imagesCHWinter.forest1} alt="forest-1" height="200"/>
      <ImageModal src={imagesCHWinter.forest2} alt="forest-2" height="200"/>
      <ImageModal src={imagesCHWinter.forest3} alt="forest-3" height="200"/>
      <ImageModal src={imagesCHWinter.forest4} alt="forest-4" height="200"/>
      <ImageModal src={imagesCHWinter.forest5} alt="forest-5" height="200"/>
      <ImageModal src={imagesCHWinter.frutt1} alt="frutt-1" height="200"/>
      <ImageModal src={imagesCHWinter.frutt2} alt="frutt-2" height="200"/>
      <ImageModal src={imagesCHWinter.frutt3} alt="frutt-3" height="200"/>
      <ImageModal src={imagesCHWinter.frutt4} alt="frutt-4" height="200"/>
      <ImageModal src={imagesCHWinter.frutt5} alt="frutt-5" height="200"/>
      <ImageModal src={imagesCHWinter.hydrant1} alt="hydrant-1" height="200"/>
      <ImageModal src={imagesCHWinter.iceleaves1} alt="iceleaves-1" height="200"/>
      <ImageModal src={imagesCHWinter.iceleaves2} alt="iceleaves-2" height="200"/>
      <ImageModal src={imagesCHWinter.lake1} alt="lake-1" height="200"/>
      <ImageModal src={imagesCHWinter.logs1} alt="logs-1" height="200"/>
      <ImageModal src={imagesCHWinter.mountain1} alt="mountain-1" height="200"/>
      <ImageModal src={imagesCHWinter.mountain2} alt="mountain-2" height="200"/>
      <ImageModal src={imagesCHWinter.mountain3} alt="mountain-3" height="200"/>
      <ImageModal src={imagesCHWinter.path1} alt="path-1" height="200"/>
      <ImageModal src={imagesCHWinter.path2} alt="path-2" height="200"/>
      <ImageModal src={imagesCHWinter.path3} alt="path-3" height="200"/>
      <ImageModal src={imagesCHWinter.piste1} alt="piste-1" height="200"/>
      <ImageModal src={imagesCHWinter.piste2} alt="piste-2" height="200"/>
      <ImageModal src={imagesCHWinter.piste3} alt="piste-3" height="200"/>
      <ImageModal src={imagesCHWinter.piste4} alt="piste-4" height="200"/>
      <ImageModal src={imagesCHWinter.piste5} alt="piste-5" height="200"/>
      <ImageModal src={imagesCHWinter.rigi1} alt="rigi-1" height="200"/>
      <ImageModal src={imagesCHWinter.road1} alt="road-1" height="200"/>
      <ImageModal src={imagesCHWinter.snowhut1} alt="snowhut-1" height="200"/>
      <ImageModal src={imagesCHWinter.snowleaves1} alt="snowleaves-1" height="200"/>
      <ImageModal src={imagesCHWinter.snowroof1} alt="snowroof-1" height="200"/>
      <ImageModal src={imagesCHWinter.snowroof2} alt="snowroof-2" height="200"/>
      <ImageModal src={imagesCHWinter.snowroof3} alt="snowroof-3" height="200"/>
      <ImageModal src={imagesCHWinter.spire1} alt="spire-1" height="200"/>
      <ImageModal src={imagesCHWinter.spire2} alt="spire-2" height="200"/>
      <ImageModal src={imagesCHWinter.star1} alt="star-1" height="200"/>
      <ImageModal src={imagesCHWinter.statue1} alt="statue-1" height="200"/>
      <ImageModal src={imagesCHWinter.stump1} alt="stump-1" height="200"/>
      <ImageModal src={imagesCHWinter.temp1} alt="temp-1" height="200"/>
      <ImageModal src={imagesCHWinter.tracks1} alt="tracks-1" height="200"/>
      <ImageModal src={imagesCHWinter.tree1} alt="tree-1" height="200"/>
      <ImageModal src={imagesCHWinter.tree2} alt="tree-2" height="200"/>
      <ImageModal src={imagesCHWinter.tree3} alt="tree-3" height="200"/>
      <ImageModal src={imagesCHWinter.twigs1} alt="twigs-1" height="200"/>
      <ImageModal src={imagesCHWinter.village1} alt="village-1" height="200"/>
      <ImageModal src={imagesCHWinter.village2} alt="village-2" height="200"/>
      <ImageModal src={imagesCHWinter.village3} alt="village-3" height="200"/>
      <ImageModal src={imagesCHWinter.village4} alt="village-4" height="200"/>
      <ImageModal src={imagesCHWinter.vista1} alt="vista-1" height="200"/>
      <ImageModal src={imagesCHWinter.vista2} alt="vista-2" height="200"/>
      <ImageModal src={imagesCHWinter.vista3} alt="vista-3" height="200"/>
      <ImageModal src={imagesCHWinter.vista4} alt="vista-4" height="200"/>
      <ImageModal src={imagesCHWinter.vista5} alt="vista-5" height="200"/>
      <ImageModal src={imagesCHWinter.vista6} alt="vista-6" height="200"/>
    </div>
  )
}
export default CHWinter