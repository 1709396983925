import { imagesMountains } from '../../components/CH/imagesMountains'
import ImageModal from '../../components/ImageModal'

function Mountains() {
  return (
    <div className="images">
      <ImageModal src={imagesMountains.bwglacier1} alt="bwglacier-1" height="200"/>
      <ImageModal src={imagesMountains.bwmountain1} alt="bwmountain-1" height="200"/>
      <ImageModal src={imagesMountains.bwmountain2} alt="bwmountain-2" height="200"/>
      <ImageModal src={imagesMountains.eggishorn1} alt="eggishorn-1" height="200"/>
      <ImageModal src={imagesMountains.furka1} alt="furka-1" height="200"/>
      <ImageModal src={imagesMountains.glacier1} alt="glacier-1" height="200"/>
      <ImageModal src={imagesMountains.glacier2} alt="glacier-2" height="200"/>
      <ImageModal src={imagesMountains.glacier3} alt="glacier-3" height="200"/>
      <ImageModal src={imagesMountains.glacier4} alt="glacier-4" height="200"/>
      <ImageModal src={imagesMountains.glacier5} alt="glacier-5" height="200"/>
      <ImageModal src={imagesMountains.glacier6} alt="glacier-6" height="200"/>
      <ImageModal src={imagesMountains.grimsel1} alt="grimsel-1" height="200"/>
      <ImageModal src={imagesMountains.grimsel2} alt="grimsel-2" height="200"/>
      <ImageModal src={imagesMountains.huts1} alt="huts-1" height="200"/>
      <ImageModal src={imagesMountains.pass1} alt="pass-1" height="200"/>
      <ImageModal src={imagesMountains.pass2} alt="pass-2" height="200"/>
      <ImageModal src={imagesMountains.pass3} alt="pass-3" height="200"/>
      <ImageModal src={imagesMountains.pass4} alt="pass-4" height="200"/>
      <ImageModal src={imagesMountains.pass5} alt="pass-5" height="200"/>
      <ImageModal src={imagesMountains.pass6} alt="pass-6" height="200"/>
      <ImageModal src={imagesMountains.pass7} alt="pass-7" height="200"/>
      <ImageModal src={imagesMountains.pass8} alt="pass-8" height="200"/>
      <ImageModal src={imagesMountains.pass9} alt="pass-9" height="200"/>
      <ImageModal src={imagesMountains.pass10} alt="pass-10" height="200"/>
      <ImageModal src={imagesMountains.pass11} alt="pass-11" height="200"/>
      <ImageModal src={imagesMountains.pass12} alt="pass-12" height="200"/>
      <ImageModal src={imagesMountains.vista1} alt="vista-1" height="200"/>
      <ImageModal src={imagesMountains.vista2} alt="vista-2" height="200"/>
      <ImageModal src={imagesMountains.vista3} alt="vista-3" height="200"/>
      <ImageModal src={imagesMountains.vista4} alt="vista-4" height="200"/>
      <ImageModal src={imagesMountains.vista5} alt="vista-5" height="200"/>
      <ImageModal src={imagesMountains.vista6} alt="vista-6" height="200"/>
      <ImageModal src={imagesMountains.vista7} alt="vista-7" height="200"/>
      <ImageModal src={imagesMountains.vista8} alt="vista-8" height="200"/>
      <ImageModal src={imagesMountains.vista9} alt="vista-9" height="200"/>
      <ImageModal src={imagesMountains.vista10} alt="vista-10" height="200"/>
      <ImageModal src={imagesMountains.vista11} alt="vista-11" height="200"/>
      <ImageModal src={imagesMountains.vista12} alt="vista-12" height="200"/>
      <ImageModal src={imagesMountains.vista13} alt="vista-13" height="200"/>
      <ImageModal src={imagesMountains.vista14} alt="vista-14" height="200"/>
      <ImageModal src={imagesMountains.vista15} alt="vista-15" height="200"/>
      <ImageModal src={imagesMountains.vista16} alt="vista-16" height="200"/>
      <ImageModal src={imagesMountains.vista17} alt="vista-17" height="200"/>
      <ImageModal src={imagesMountains.vista18} alt="vista-18" height="200"/>
      <ImageModal src={imagesMountains.vista19} alt="vista-19" height="200"/>
      <ImageModal src={imagesMountains.vista20} alt="vista-20" height="200"/>
      <ImageModal src={imagesMountains.vista21} alt="vista-21" height="200"/>
      <ImageModal src={imagesMountains.vista22} alt="vista-22" height="200"/>
      <ImageModal src={imagesMountains.vista23} alt="vista-23" height="200"/>
      <ImageModal src={imagesMountains.vista24} alt="vista-24" height="200"/>
      <ImageModal src={imagesMountains.vista25} alt="vista-25" height="200"/>
    </div>
  )
}
export default Mountains