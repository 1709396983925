import bwwall1 from '../../../../public/static/img/US/west/zion/bwwall1.jpg'
import bwwall2 from '../../../../public/static/img/US/west/zion/bwwall2.jpg'
import horizon1 from '../../../../public/static/img/US/west/zion/horizon1.jpg'
import mountains1 from '../../../../public/static/img/US/west/zion/mountains1.jpg'
import mountains2 from '../../../../public/static/img/US/west/zion/mountains2.jpg'
import mountains3 from '../../../../public/static/img/US/west/zion/mountains3.jpg'
import mountains4 from '../../../../public/static/img/US/west/zion/mountains4.jpg'
import mountains5 from '../../../../public/static/img/US/west/zion/mountains5.jpg'
import mountains6 from '../../../../public/static/img/US/west/zion/mountains6.jpg'
import mountains7 from '../../../../public/static/img/US/west/zion/mountains7.jpg'
import mountains8 from '../../../../public/static/img/US/west/zion/mountains8.jpg'
import mountains9 from '../../../../public/static/img/US/west/zion/mountains9.jpg'
import mountains10 from '../../../../public/static/img/US/west/zion/mountains10.jpg'
import path1 from '../../../../public/static/img/US/west/zion/path1.jpg'
import river1 from '../../../../public/static/img/US/west/zion/river1.jpg'
import river2 from '../../../../public/static/img/US/west/zion/river2.jpg'
import valley1 from '../../../../public/static/img/US/west/zion/valley1.jpg'
import wall1 from '../../../../public/static/img/US/west/zion/wall1.jpg'
import wall2 from '../../../../public/static/img/US/west/zion/wall2.jpg'
import wall3 from '../../../../public/static/img/US/west/zion/wall3.jpg'
import yellowflower1 from '../../../../public/static/img/US/west/zion/yellowflower1.jpg'

export const imagesZion = {
  bwwall1,
  bwwall2,
  horizon1,
  mountains1,
  mountains2,
  mountains3,
  mountains4,
  mountains5,
  mountains6,
  mountains7,
  mountains8,
  mountains9,
  mountains10,
  path1,
  river1,
  river2,
  valley1,
  wall1,
  wall2,
  wall3,
  yellowflower1
}