import aerialdam1 from '../../../../public/static/img/US/west/hoover/aerialdam1.jpg'
import bridge1 from '../../../../public/static/img/US/west/hoover/bridge1.jpg'
import camaro1 from '../../../../public/static/img/US/west/hoover/camaro1.jpg'
import dam1 from '../../../../public/static/img/US/west/hoover/dam1.jpg'
import dam2 from '../../../../public/static/img/US/west/hoover/dam2.jpg'
import damout1 from '../../../../public/static/img/US/west/hoover/damout1.jpg'
import damout2 from '../../../../public/static/img/US/west/hoover/damout2.jpg'
import fisheyedam1 from '../../../../public/static/img/US/west/hoover/fisheyedam1.jpg'
import fisheyedamout1 from '../../../../public/static/img/US/west/hoover/fisheyedamout1.jpg'
import fulldam1 from '../../../../public/static/img/US/west/hoover/fulldam1.jpg'
import fulldam2 from '../../../../public/static/img/US/west/hoover/fulldam2.jpg'
import fulldam3 from '../../../../public/static/img/US/west/hoover/fulldam3.jpg'
import fulldam4 from '../../../../public/static/img/US/west/hoover/fulldam4.jpg'
import hills1 from '../../../../public/static/img/US/west/hoover/hills1.jpg'
import lakemead1 from '../../../../public/static/img/US/west/hoover/lakemead1.jpg'
import lakemead2 from '../../../../public/static/img/US/west/hoover/lakemead2.jpg'
import lakemead3 from '../../../../public/static/img/US/west/hoover/lakemead3.jpg'
import lakemead4 from '../../../../public/static/img/US/west/hoover/lakemead4.jpg'
import towers1 from '../../../../public/static/img/US/west/hoover/towers1.jpg'
import towers2 from '../../../../public/static/img/US/west/hoover/towers2.jpg'

export const imagesHoover = {
  aerialdam1,
  bridge1,
  camaro1,
  dam1,
  dam2,
  damout1,
  damout2,
  fisheyedam1,
  fisheyedamout1,
  fulldam1,
  fulldam2,
  fulldam3,
  fulldam4,
  hills1,
  lakemead1,
  lakemead2,
  lakemead3,
  lakemead4,
  towers1,
  towers2
}