import { imagesBruges } from '../../components/Europe/imagesBruges'
import ImageModal from '../../components/ImageModal'

function Bruges() {
  return (
    <div className="images">
      <ImageModal src={imagesBruges.aerial1} alt="aerial-1" height="200"/>
      <ImageModal src={imagesBruges.babel1} alt="babel-1" height="200"/>
      <ImageModal src={imagesBruges.bridge1} alt="bridge-1" height="200"/>
      <ImageModal src={imagesBruges.bridge2} alt="bridge-2" height="200"/>
      <ImageModal src={imagesBruges.bridge3} alt="bridge-3" height="200"/>
      <ImageModal src={imagesBruges.bridge4} alt="bridge-4" height="200"/>
      <ImageModal src={imagesBruges.bridge5} alt="bridge-5" height="200"/>
      <ImageModal src={imagesBruges.buttress1} alt="buttress-1" height="200"/>
      <ImageModal src={imagesBruges.canal1} alt="canal-1" height="200"/>
      <ImageModal src={imagesBruges.canal2} alt="canal-2" height="200"/>
      <ImageModal src={imagesBruges.canal3} alt="canal-3" height="200"/>
      <ImageModal src={imagesBruges.fabric1} alt="fabric-1" height="200"/>
      <ImageModal src={imagesBruges.faces1} alt="faces-1" height="200"/>
      <ImageModal src={imagesBruges.leaf1} alt="leaf-1" height="200"/>
      <ImageModal src={imagesBruges.mural1} alt="mural-1" height="200"/>
      <ImageModal src={imagesBruges.reflection1} alt="reflection-1" height="200"/>
      <ImageModal src={imagesBruges.reflection2} alt="reflection-2" height="200"/>
      <ImageModal src={imagesBruges.sculpture1} alt="sculpture-1" height="200"/>
      <ImageModal src={imagesBruges.spire1} alt="spire-1" height="200"/>
      <ImageModal src={imagesBruges.swan1} alt="swan-1" height="200"/>
      <ImageModal src={imagesBruges.tower1} alt="tower-1" height="200"/>
      <ImageModal src={imagesBruges.trails1} alt="trails-1" height="200"/>
    </div>
  )
}
export default Bruges