import beach1 from '../../../../public/static/img/US/west/sr1/beach1.jpg'
import bridge1 from '../../../../public/static/img/US/west/sr1/bridge1.jpg'
import coast1 from '../../../../public/static/img/US/west/sr1/coast1.jpg'
import coast2 from '../../../../public/static/img/US/west/sr1/coast2.jpg'
import coast3 from '../../../../public/static/img/US/west/sr1/coast3.jpg'
import coast4 from '../../../../public/static/img/US/west/sr1/coast4.jpg'
import coast5 from '../../../../public/static/img/US/west/sr1/coast5.jpg'
import coast6 from '../../../../public/static/img/US/west/sr1/coast6.jpg'
import coast7 from '../../../../public/static/img/US/west/sr1/coast7.jpg'
import coast8 from '../../../../public/static/img/US/west/sr1/coast8.jpg'
import coast9 from '../../../../public/static/img/US/west/sr1/coast9.jpg'
import coast10 from '../../../../public/static/img/US/west/sr1/coast10.jpg'
import coast11 from '../../../../public/static/img/US/west/sr1/coast11.jpg'
import coast12 from '../../../../public/static/img/US/west/sr1/coast12.jpg'
import coast13 from '../../../../public/static/img/US/west/sr1/coast13.jpg'
import coast14 from '../../../../public/static/img/US/west/sr1/coast14.jpg'
import coast15 from '../../../../public/static/img/US/west/sr1/coast15.jpg'
import hills1 from '../../../../public/static/img/US/west/sr1/hills1.jpg'
import hills2 from '../../../../public/static/img/US/west/sr1/hills2.jpg'
import hills3 from '../../../../public/static/img/US/west/sr1/hills3.jpg'
import ocean1 from '../../../../public/static/img/US/west/sr1/ocean1.jpg'
import ocean2 from '../../../../public/static/img/US/west/sr1/ocean2.jpg'
import rodent1 from '../../../../public/static/img/US/west/sr1/rodent1.jpg'
import seal1 from '../../../../public/static/img/US/west/sr1/seal1.jpg'
import seals1 from '../../../../public/static/img/US/west/sr1/seals1.jpg'
import seals2 from '../../../../public/static/img/US/west/sr1/seals2.jpg'
import seals3 from '../../../../public/static/img/US/west/sr1/seals3.jpg'
import seals4 from '../../../../public/static/img/US/west/sr1/seals4.jpg'
import seals5 from '../../../../public/static/img/US/west/sr1/seals5.jpg'
import seals6 from '../../../../public/static/img/US/west/sr1/seals6.jpg'
import seals7 from '../../../../public/static/img/US/west/sr1/seals7.jpg'
import seals8 from '../../../../public/static/img/US/west/sr1/seals8.jpg'
import seals9 from '../../../../public/static/img/US/west/sr1/seals9.jpg'
import seals10 from '../../../../public/static/img/US/west/sr1/seals10.jpg'

export const imagesSR1 = {
  beach1,
  bridge1,
  coast1,
  coast2,
  coast3,
  coast4,
  coast5,
  coast6,
  coast7,
  coast8,
  coast9,
  coast10,
  coast11,
  coast12,
  coast13,
  coast14,
  coast15,
  hills1,
  hills2,
  hills3,
  ocean1,
  ocean2,
  rodent1,
  seal1,
  seals1,
  seals2,
  seals3,
  seals4,
  seals5,
  seals6,
  seals7,
  seals8,
  seals9,
  seals10
}