import bwfisheyehorseshoe1 from '../../../../public/static/img/US/west/horseshoe/bwfisheyehorseshoe1.jpg'
import bwhorseshoe1 from '../../../../public/static/img/US/west/horseshoe/bwhorseshoe1.jpg'
import horseshoe1 from '../../../../public/static/img/US/west/horseshoe/horseshoe1.jpg'
import horseshoe2 from '../../../../public/static/img/US/west/horseshoe/horseshoe2.jpg'
import sky1 from '../../../../public/static/img/US/west/horseshoe/sky1.jpg'

export const imagesHorseshoe = {
  bwfisheyehorseshoe1,
  bwhorseshoe1,
  horseshoe1,
  horseshoe2,
  sky1
}