import arcade1 from '../../public/static/img/vancouver/arcade1.jpg'
import bird1 from '../../public/static/img/vancouver/bird1.jpg'
import boats1 from '../../public/static/img/vancouver/boats1.jpg'
import cable1 from '../../public/static/img/vancouver/cable1.jpg'
import dandelion1 from '../../public/static/img/vancouver/dandelion1.jpg'
import duck1 from '../../public/static/img/vancouver/duck1.jpg'
import flight1 from '../../public/static/img/vancouver/flight1.jpg'
import flight2 from '../../public/static/img/vancouver/flight2.jpg'
import flight3 from '../../public/static/img/vancouver/flight3.jpg'
import flight4 from '../../public/static/img/vancouver/flight4.jpg'
import forest1 from '../../public/static/img/vancouver/forest1.jpg'
import forest2 from '../../public/static/img/vancouver/forest2.jpg'
import forest3 from '../../public/static/img/vancouver/forest3.jpg'
import forest4 from '../../public/static/img/vancouver/forest4.jpg'
import geese1 from '../../public/static/img/vancouver/geese1.jpg'
import horizon1 from '../../public/static/img/vancouver/horizon1.jpg'
import horizon2 from '../../public/static/img/vancouver/horizon2.jpg'
import hotelview1 from '../../public/static/img/vancouver/hotelview1.jpg'
import hotelview2 from '../../public/static/img/vancouver/hotelview2.jpg'
import hotelview3 from '../../public/static/img/vancouver/hotelview3.jpg'
import hydrant1 from '../../public/static/img/vancouver/hydrant1.jpg'
import lake1 from '../../public/static/img/vancouver/lake1.jpg'
import leaf1 from '../../public/static/img/vancouver/leaf1.jpg'
import leaf2 from '../../public/static/img/vancouver/leaf2.jpg'
import leaf3 from '../../public/static/img/vancouver/leaf3.jpg'
import mountains1 from '../../public/static/img/vancouver/mountains1.jpg'
import mountains2 from '../../public/static/img/vancouver/mountains2.jpg'
import paintcan1 from '../../public/static/img/vancouver/paintcan1.jpg'
import path1 from '../../public/static/img/vancouver/path1.jpg'
import pinkflower1 from '../../public/static/img/vancouver/pinkflower1.jpg'
import pinkflower2 from '../../public/static/img/vancouver/pinkflower2.jpg'
import pinkflower3 from '../../public/static/img/vancouver/pinkflower3.jpg'
import post1 from '../../public/static/img/vancouver/post1.jpg'
import raven1 from '../../public/static/img/vancouver/raven1.jpg'
import redflower1 from '../../public/static/img/vancouver/redflower1.jpg'
import redflower2 from '../../public/static/img/vancouver/redflower2.jpg'
import redflower3 from '../../public/static/img/vancouver/redflower3.jpg'
import redflower4 from '../../public/static/img/vancouver/redflower4.jpg'
import redyellowflower1 from '../../public/static/img/vancouver/redflower1.jpg'
import redyellowflower2 from '../../public/static/img/vancouver/redflower2.jpg'
import squirrel1 from '../../public/static/img/vancouver/squirrel1.jpg'
import texture1 from '../../public/static/img/vancouver/texture1.jpg'
import totem1 from '../../public/static/img/vancouver/totem1.jpg'
import totem2 from '../../public/static/img/vancouver/totem2.jpg'
import totem3 from '../../public/static/img/vancouver/totem3.jpg'
import tower1 from '../../public/static/img/vancouver/tower1.jpg'
import tower2 from '../../public/static/img/vancouver/tower2.jpg'
import whiteflower1 from '../../public/static/img/vancouver/whiteflower1.jpg'

export const imagesVancouver = {
  arcade1,
  bird1,
  boats1,
  cable1,
  dandelion1,
  duck1,
  flight1,
  flight2,
  flight3,
  flight4,
  forest1,
  forest2,
  forest3,
  forest4,
  geese1,
  horizon1,
  horizon2,
  hotelview1,
  hotelview2,
  hotelview3,
  hydrant1,
  lake1,
  leaf1,
  leaf2,
  leaf3,
  mountains1,
  mountains2,
  paintcan1,
  path1,
  pinkflower1,
  pinkflower2,
  pinkflower3,
  post1,
  raven1,
  redflower1,
  redflower2,
  redflower3,
  redflower4,
  redyellowflower1,
  redyellowflower2,
  squirrel1,
  texture1,
  totem1,
  totem2,
  totem3,
  tower1,
  tower2,
  whiteflower1
}