import blue1 from '../../public/static/img/colour/blue1.jpg'
import blue2 from '../../public/static/img/colour/blue2.jpg'
import bw1 from '../../public/static/img/colour/bw1.jpg'
import bw2 from '../../public/static/img/colour/bw2.jpg'
import green1 from '../../public/static/img/colour/green1.jpg'
import greenpink1 from '../../public/static/img/colour/greenpink1.jpg'
import greenpink2 from '../../public/static/img/colour/greenpink2.jpg'
import orange1 from '../../public/static/img/colour/orange1.jpg'
import orange2 from '../../public/static/img/colour/orange2.jpg'
import orange3 from '../../public/static/img/colour/orange3.jpg'
import orangeblue1 from '../../public/static/img/colour/orangeblue1.jpg'
import pink1 from '../../public/static/img/colour/pink1.jpg'
import pink2 from '../../public/static/img/colour/pink2.jpg'
import pink3 from '../../public/static/img/colour/pink3.jpg'
import pink4 from '../../public/static/img/colour/pink4.jpg'
import pink5 from '../../public/static/img/colour/pink5.jpg'
import pink6 from '../../public/static/img/colour/pink6.jpg'
import pink7 from '../../public/static/img/colour/pink7.jpg'
import pinkwhite1 from '../../public/static/img/colour/pinkwhite1.jpg'
import pinkwhite2 from '../../public/static/img/colour/pinkwhite2.jpg'
import purple1 from '../../public/static/img/colour/purple1.jpg'
import purple2 from '../../public/static/img/colour/purple2.jpg'
import purple3 from '../../public/static/img/colour/purple3.jpg'
import purple4 from '../../public/static/img/colour/purple4.jpg'
import purple5 from '../../public/static/img/colour/purple5.jpg'
import purple6 from '../../public/static/img/colour/purple6.jpg'
import purpleyellow1 from '../../public/static/img/colour/purpleyellow1.jpg'
import red1 from '../../public/static/img/colour/red1.jpg'
import red2 from '../../public/static/img/colour/red2.jpg'
import red3 from '../../public/static/img/colour/red3.jpg'
import red4 from '../../public/static/img/colour/red4.jpg'
import red5 from '../../public/static/img/colour/red5.jpg'
import redgreen1 from '../../public/static/img/colour/redgreen1.jpg'
import redyellow1 from '../../public/static/img/colour/redyellow1.jpg'
import redyellow2 from '../../public/static/img/colour/redyellow2.jpg'
import redyellow3 from '../../public/static/img/colour/redyellow3.jpg'
import white1 from '../../public/static/img/colour/white1.jpg'
import whitepink1 from '../../public/static/img/colour/whitepink1.jpg'
import whitepink2 from '../../public/static/img/colour/whitepink2.jpg'
import yellow1 from '../../public/static/img/colour/yellow1.jpg'
import yellow2 from '../../public/static/img/colour/yellow2.jpg'

export const imagesColour = {
  blue1,
  blue2,
  bw1,
  bw2,
  green1,
  greenpink1,
  greenpink2,
  orange1,
  orange2,
  orange3,
  orangeblue1,
  pink1,
  pink2,
  pink3,
  pink4,
  pink5,
  pink6,
  pink7,
  pinkwhite1,
  pinkwhite2,
  purple1,
  purple2,
  purple3,
  purple4,
  purple5,
  purple6,
  purpleyellow1,
  red1,
  red2,
  red3,
  red4,
  red5,
  redgreen1,
  redyellow1,
  redyellow2,
  redyellow3,
  white1,
  whitepink1,
  whitepink2,
  yellow1,
  yellow2,
}