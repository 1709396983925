import aluminium1 from '../../public/static/img/abstract/aluminium1.jpg'
import bench1 from '../../public/static/img/abstract/bench1.jpg'
import bench2 from '../../public/static/img/abstract/bench2.jpg'
import bunderberg1 from '../../public/static/img/abstract/bunderberg1.jpg'
import bunderberg2 from '../../public/static/img/abstract/bunderberg2.jpg'
import cabinet1 from '../../public/static/img/abstract/cabinet1.jpg'
import case1 from '../../public/static/img/abstract/case1.jpg'
import case2 from '../../public/static/img/abstract/case2.jpg'
import chair1 from '../../public/static/img/abstract/chair1.jpg'
import coin1 from '../../public/static/img/abstract/coin1.jpg'
import coin2 from '../../public/static/img/abstract/coin2.jpg'
import coin3 from '../../public/static/img/abstract/coin3.jpg'
import coin4 from '../../public/static/img/abstract/coin4.jpg'
import cufflinks1 from '../../public/static/img/abstract/cufflinks1.jpg'
import cursive1 from '../../public/static/img/abstract/cursive1.jpg'
import cushion1 from '../../public/static/img/abstract/cushion1.jpg'
import cushion2 from '../../public/static/img/abstract/cushion2.jpg'
import gatepost1 from '../../public/static/img/abstract/gatepost1.jpg'
import gilded1 from '../../public/static/img/abstract/gilded1.jpg'
import hydrant1 from '../../public/static/img/abstract/hydrant1.jpg'
import lock1 from '../../public/static/img/abstract/lock1.jpg'
import mirror1 from '../../public/static/img/abstract/mirror1.jpg'
import note1 from '../../public/static/img/abstract/note1.jpg'
import note2 from '../../public/static/img/abstract/note2.jpg'
import pint1 from '../../public/static/img/abstract/pint1.jpg'
import pipe1 from '../../public/static/img/abstract/pipe1.jpg'
import pipe2 from '../../public/static/img/abstract/pipe2.jpg'
import post1 from '../../public/static/img/abstract/post1.jpg'
import post2 from '../../public/static/img/abstract/post2.jpg'
import rails1 from '../../public/static/img/abstract/rails1.jpg'
import rings1 from '../../public/static/img/abstract/rings1.jpg'
import rocks1 from '../../public/static/img/abstract/rocks1.jpg'
import sofa1 from '../../public/static/img/abstract/sofa1.jpg'
import symmetry1 from '../../public/static/img/abstract/symmetry1.jpg'
import symmetry2 from '../../public/static/img/abstract/symmetry2.jpg'
import wall1 from '../../public/static/img/abstract/wall1.jpg'
import wall2 from '../../public/static/img/abstract/wall2.jpg'
import wall3 from '../../public/static/img/abstract/wall3.jpg'
import walnuts1 from '../../public/static/img/abstract/walnuts1.jpg'
import watch1 from '../../public/static/img/abstract/watch1.jpg'
import watch2 from '../../public/static/img/abstract/watch2.jpg'
import wood1 from '../../public/static/img/abstract/wood1.jpg'

export const imagesAbstract = {
  aluminium1,
  bench1,
  bench2,
  bunderberg1,
  bunderberg2,
  cabinet1,
  case1,
  case2,
  chair1,
  coin1,
  coin2,
  coin3,
  coin4,
  cufflinks1,
  cursive1,
  cushion1,
  cushion2,
  gatepost1,
  gilded1,
  hydrant1,
  lock1,
  mirror1,
  note1,
  note2,
  pint1,
  pipe1,
  pipe2,
  post1,
  post2,
  rails1,
  rings1,
  rocks1,
  sofa1,
  symmetry1,
  symmetry2,
  wall1,
  wall2,
  wall3,
  walnuts1,
  watch1,
  watch2,
  wood1
}