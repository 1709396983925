import camaro1 from '../../../../public/static/img/US/west/yosemite/camaro1.jpg'
import camaro2 from '../../../../public/static/img/US/west/yosemite/camaro2.jpg'
import elcapitan1 from '../../../../public/static/img/US/west/yosemite/elcapitan1.jpg'
import elcapitan2 from '../../../../public/static/img/US/west/yosemite/elcapitan2.jpg'
import halfdome1 from '../../../../public/static/img/US/west/yosemite/halfdome1.jpg'
import halfdome2 from '../../../../public/static/img/US/west/yosemite/halfdome2.jpg'
import halfdome3 from '../../../../public/static/img/US/west/yosemite/halfdome3.jpg'
import halfdome4 from '../../../../public/static/img/US/west/yosemite/halfdome4.jpg'
import halfdome5 from '../../../../public/static/img/US/west/yosemite/halfdome5.jpg'
import halfdome6 from '../../../../public/static/img/US/west/yosemite/halfdome6.jpg'
import halfdome7 from '../../../../public/static/img/US/west/yosemite/halfdome7.jpg'
import halfdome8 from '../../../../public/static/img/US/west/yosemite/halfdome8.jpg'
import mountains1 from '../../../../public/static/img/US/west/yosemite/mountains1.jpg'
import snowpeak1 from '../../../../public/static/img/US/west/yosemite/snowpeak1.jpg'
import snowpeak2 from '../../../../public/static/img/US/west/yosemite/snowpeak2.jpg'
import valley1 from '../../../../public/static/img/US/west/yosemite/valley1.jpg'
import waterfall1 from '../../../../public/static/img/US/west/yosemite/waterfall1.jpg'
import waterfall2 from '../../../../public/static/img/US/west/yosemite/waterfall2.jpg'
import waterfall3 from '../../../../public/static/img/US/west/yosemite/waterfall3.jpg'
import waterfall4 from '../../../../public/static/img/US/west/yosemite/waterfall4.jpg'

export const imagesYosemite = {
  camaro1,
  camaro2,
  elcapitan1,
  elcapitan2,
  halfdome1,
  halfdome2,
  halfdome3,
  halfdome4,
  halfdome5,
  halfdome6,
  halfdome7,
  halfdome8,
  mountains1,
  snowpeak1,
  snowpeak2,
  valley1,
  waterfall1,
  waterfall2,
  waterfall3,
  waterfall4
}