import archives1 from '../../../../public/static/img/US/northeast/dc/archives1.jpg'
import badge1 from '../../../../public/static/img/US/northeast/dc/badge1.jpg'
import columns1 from '../../../../public/static/img/US/northeast/dc/columns1.jpg'
import dodge1 from '../../../../public/static/img/US/northeast/dc/dodge1.jpg'
import eagle1 from '../../../../public/static/img/US/northeast/dc/eagle1.jpg'
import freedom1 from '../../../../public/static/img/US/northeast/dc/freedom1.jpg'
import lockheed1 from '../../../../public/static/img/US/northeast/dc/lockheed1.jpg'
import memorial1 from '../../../../public/static/img/US/northeast/dc/memorial1.jpg'
import memorial2 from '../../../../public/static/img/US/northeast/dc/memorial2.jpg'
import mobil1 from '../../../../public/static/img/US/northeast/dc/mobil1.jpg'
import monument1 from '../../../../public/static/img/US/northeast/dc/monument1.jpg'
import patch1 from '../../../../public/static/img/US/northeast/dc/patch1.jpg'
import peterbilt1 from '../../../../public/static/img/US/northeast/dc/peterbilt1.jpg'
import pinkflower1 from '../../../../public/static/img/US/northeast/dc/pinkflower1.jpg'
import poster1 from '../../../../public/static/img/US/northeast/dc/poster1.jpg'
import poster2 from '../../../../public/static/img/US/northeast/dc/poster2.jpg'
import pump1 from '../../../../public/static/img/US/northeast/dc/pump1.jpg'
import rails1 from '../../../../public/static/img/US/northeast/dc/rails1.jpg'
import redflower1 from '../../../../public/static/img/US/northeast/dc/redflower1.jpg'
import redleaves1 from '../../../../public/static/img/US/northeast/dc/redleaves1.jpg'
import redyellowflower1 from '../../../../public/static/img/US/northeast/dc/redyellowflower1.jpg'
import service1 from '../../../../public/static/img/US/northeast/dc/service1.jpg'
import sigil1 from '../../../../public/static/img/US/northeast/dc/sigil1.jpg'
import statue1 from '../../../../public/static/img/US/northeast/dc/statue1.jpg'
import statue2 from '../../../../public/static/img/US/northeast/dc/statue2.jpg'
import statue3 from '../../../../public/static/img/US/northeast/dc/statue3.jpg'
import statue4 from '../../../../public/static/img/US/northeast/dc/statue4.jpg'
import statue5 from '../../../../public/static/img/US/northeast/dc/statue5.jpg'
import statue6 from '../../../../public/static/img/US/northeast/dc/statue6.jpg'
import warrenite1 from '../../../../public/static/img/US/northeast/dc/warrenite1.jpg'
import whitehouse1 from '../../../../public/static/img/US/northeast/dc/whitehouse1.jpg'

export const imagesDC = {
  archives1,
  badge1,
  columns1,
  dodge1,
  eagle1,
  freedom1,
  lockheed1,
  memorial1,
  memorial2,
  mobil1,
  monument1,
  patch1,
  peterbilt1,
  pinkflower1,
  poster1,
  poster2,
  pump1,
  rails1,
  redflower1,
  redleaves1,
  redyellowflower1,
  service1,
  sigil1,
  statue1,
  statue2,
  statue3,
  statue4,
  statue5,
  statue6,
  warrenite1,
  whitehouse1  
}