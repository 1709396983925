import { imagesMoab } from '../../../components/US/West/imagesMoab'
import ImageModal from '../../../components/ImageModal'

function Moab() {
  return (
    <div className="images">
      <ImageModal src={imagesMoab.arch1} alt="arch-1" height="200"/>
      <ImageModal src={imagesMoab.arch2} alt="arch-2" height="200"/>
      <ImageModal src={imagesMoab.arch3} alt="arch-3" height="200"/>
      <ImageModal src={imagesMoab.bwtree1} alt="bwtree-1" height="200"/>
      <ImageModal src={imagesMoab.bwtree2} alt="bwtree-2" height="200"/>
      <ImageModal src={imagesMoab.bwtree3} alt="bwtree-3" height="200"/>
      <ImageModal src={imagesMoab.canyonlands1} alt="canyonlands-1" height="200"/>
      <ImageModal src={imagesMoab.canyonlands2} alt="canyonlands-2" height="200"/>
      <ImageModal src={imagesMoab.canyonlands3} alt="canyonlands-3" height="200"/>
      <ImageModal src={imagesMoab.canyonlands4} alt="canyonlands-4" height="200"/>
      <ImageModal src={imagesMoab.cliffs1} alt="cliffs-1" height="200"/>
      <ImageModal src={imagesMoab.cliffs2} alt="cliffs-2" height="200"/>
      <ImageModal src={imagesMoab.cliffs3} alt="cliffs-3" height="200"/>
      <ImageModal src={imagesMoab.highway1} alt="highway-1" height="200"/>
      <ImageModal src={imagesMoab.highway2} alt="highway-2" height="200"/>
      <ImageModal src={imagesMoab.hoodoo1} alt="hoodoo-1" height="200"/>
      <ImageModal src={imagesMoab.horizon1} alt="horizon-1" height="200"/>
      <ImageModal src={imagesMoab.horizon2} alt="horizon-2" height="200"/>
      <ImageModal src={imagesMoab.horizon3} alt="horizon-3" height="200"/>
      <ImageModal src={imagesMoab.scrub1} alt="scrub-1" height="200"/>
      <ImageModal src={imagesMoab.tree1} alt="tree-1" height="200"/>
      <ImageModal src={imagesMoab.river1} alt="river-1" height="200"/>
      <ImageModal src={imagesMoab.arch4} alt="arch-4" height="200"/>
    </div>
  )
}
export default Moab