import antelope1 from '../../public/static/img/wildlife/antelope1.jpg'
import bear1 from '../../public/static/img/wildlife/bear1.jpg'
import cat1 from '../../public/static/img/wildlife/cat1.jpg'
import cow1 from '../../public/static/img/wildlife/cow1.jpg'
import cow2 from '../../public/static/img/wildlife/cow2.jpg'
import deer1 from '../../public/static/img/wildlife/deer1.jpg'
import duck1 from '../../public/static/img/wildlife/duck1.jpg'
import duck2 from '../../public/static/img/wildlife/duck2.jpg'
import finch1 from '../../public/static/img/wildlife/finch1.jpg'
import finch2 from '../../public/static/img/wildlife/finch2.jpg'
import finch3 from '../../public/static/img/wildlife/finch3.jpg'
import gibbon1 from '../../public/static/img/wildlife/gibbon1.jpg'
import gibbon2 from '../../public/static/img/wildlife/gibbon2.jpg'
import goat1 from '../../public/static/img/wildlife/goat1.jpg'
import goose1 from '../../public/static/img/wildlife/goose1.jpg'
import goose2 from '../../public/static/img/wildlife/goose2.jpg'
import goose3 from '../../public/static/img/wildlife/goose3.jpg'
import goose4 from '../../public/static/img/wildlife/goose4.jpg'
import gull1 from '../../public/static/img/wildlife/gull1.jpg'
import hippo1 from '../../public/static/img/wildlife/hippo1.jpg'
import koala1 from '../../public/static/img/wildlife/koala1.jpg'
import leopard1 from '../../public/static/img/wildlife/leopard1.jpg'
import leopard2 from '../../public/static/img/wildlife/leopard2.jpg'
import lion1 from '../../public/static/img/wildlife/lion1.jpg'
import lion2 from '../../public/static/img/wildlife/lion2.jpg'
import peacock1 from '../../public/static/img/wildlife/peacock1.jpg'
import peacock2 from '../../public/static/img/wildlife/peacock2.jpg'
import peacock3 from '../../public/static/img/wildlife/peacock3.jpg'
import penguin1 from '../../public/static/img/wildlife/penguin1.jpg'
import redpanda1 from '../../public/static/img/wildlife/redpanda1.jpg'
import swan1 from '../../public/static/img/wildlife/swan1.jpg'

export const imagesWildlife = {
  antelope1,
  bear1,
  cat1,
  cow1,
  cow2,
  deer1,
  duck1,
  duck2,
  finch1,
  finch2,
  finch3,
  gibbon1,
  gibbon2,
  goat1,
  goose1,
  goose2,
  goose3,
  goose4,
  gull1,
  hippo1,
  koala1,
  leopard1,
  leopard2,
  lion1,
  lion2,
  peacock1,
  peacock2,
  peacock3,
  penguin1,
  redpanda1,
  swan1
}