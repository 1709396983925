import aria1 from '../../../../public/static/img/US/west/vegas/aria1.jpg'
import bellagio1 from '../../../../public/static/img/US/west/vegas/bellagio1.jpg'
import bills1 from '../../../../public/static/img/US/west/vegas/bills1.jpg'
import caesars1 from '../../../../public/static/img/US/west/vegas/caesars1.jpg'
import caesars2 from '../../../../public/static/img/US/west/vegas/caesars2.jpg'
import encore1 from '../../../../public/static/img/US/west/vegas/encore1.jpg'
import flamingo1 from '../../../../public/static/img/US/west/vegas/flamingo1.jpg'
import flamingo2 from '../../../../public/static/img/US/west/vegas/flamingo2.jpg'
import fury1 from '../../../../public/static/img/US/west/vegas/fury1.jpg'
import fury2 from '../../../../public/static/img/US/west/vegas/fury2.jpg'
import mandarin1 from '../../../../public/static/img/US/west/vegas/mandarin1.jpg'
import mandarin2 from '../../../../public/static/img/US/west/vegas/mandarin2.jpg'
import mirage1 from '../../../../public/static/img/US/west/vegas/mirage1.jpg'
import mustang1 from '../../../../public/static/img/US/west/vegas/mustang1.jpg'
import palazzo1 from '../../../../public/static/img/US/west/vegas/palazzo1.jpg'
import paris1 from '../../../../public/static/img/US/west/vegas/paris1.jpg'
import paris2 from '../../../../public/static/img/US/west/vegas/paris2.jpg'
import paris3 from '../../../../public/static/img/US/west/vegas/paris3.jpg'
import paris4 from '../../../../public/static/img/US/west/vegas/paris4.jpg'
import strat1 from '../../../../public/static/img/US/west/vegas/strat1.jpg'
import strat2 from '../../../../public/static/img/US/west/vegas/strat2.jpg'
import strip1 from '../../../../public/static/img/US/west/vegas/strip1.jpg'
import strip2 from '../../../../public/static/img/US/west/vegas/strip2.jpg'
import sunrise1 from '../../../../public/static/img/US/west/vegas/sunrise1.jpg'
import sunrise2 from '../../../../public/static/img/US/west/vegas/sunrise2.jpg'
import sunrise3 from '../../../../public/static/img/US/west/vegas/sunrise3.jpg'
import sunrise4 from '../../../../public/static/img/US/west/vegas/sunrise4.jpg'
import sunrise5 from '../../../../public/static/img/US/west/vegas/sunrise5.jpg'
import treasureisland1 from '../../../../public/static/img/US/west/vegas/treasureisland1.jpg'
import venetian1 from '../../../../public/static/img/US/west/vegas/venetian1.jpg'

export const imagesVegas = {
  aria1,
  bellagio1,
  bills1,
  caesars1,
  caesars2,
  encore1,
  flamingo1,
  flamingo2,
  fury1,
  fury2,
  mandarin1,
  mandarin2,
  mirage1,
  mustang1,
  palazzo1,
  paris1,
  paris2,
  paris3,
  paris4,
  strat1,
  strat2,
  strip1,
  strip2,
  sunrise1,
  sunrise2,
  sunrise3,
  sunrise4,
  sunrise5,
  treasureisland1,
  venetian1
}