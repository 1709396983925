import statue1 from '../../../public/static/img/Europe/rome/statue1.jpg'
import lion2 from '../../../public/static/img/Europe/malta/lion2.jpg'
import faces1 from '../../../public/static/img/Europe/bruges/faces1.jpg'
import door1 from '../../../public/static/img/Europe/barcelona/door1.jpg'

import Image from '../../components/Image'

import { Link } from "react-router-dom"

function Europe() {
  return (
    <div className='images imagesHome'>
      <div className="linkImage">
        <Link to='/rome'>
          <Image src={statue1} alt="rome" height="200"/>
          <p className="imageLabel">Rome</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/malta'>
          <Image src={lion2} alt="malta" height="200"/>
          <p className="imageLabel">Malta</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/barcelona'>
          <Image src={door1} alt="barcelona" height="200"/>
          <p className="imageLabel">Barcelona</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/bruges'>
          <Image src={faces1} alt="bruges" height="200"/>
          <p className="imageLabel">Bruges</p>
        </Link>
      </div>
    </div>
  )
}
export default Europe