import { imagesGuarda } from '../../components/CH/imagesGuarda'
import ImageModal from '../../components/ImageModal'

function Guarda() {
  return (
    <div className="images">
      <ImageModal src={imagesGuarda.castle1} alt="castle-1" height="200"/>
      <ImageModal src={imagesGuarda.door1} alt="door-1" height="200"/>
      <ImageModal src={imagesGuarda.door2} alt="door-2" height="200"/>
      <ImageModal src={imagesGuarda.fountain1} alt="fountain-1" height="200"/>
      <ImageModal src={imagesGuarda.house1} alt="house-1" height="200"/>
      <ImageModal src={imagesGuarda.lawn1} alt="lawn-1" height="200"/>
      <ImageModal src={imagesGuarda.mountain1} alt="mountain-1" height="200"/>
      <ImageModal src={imagesGuarda.mountain2} alt="mountain-2" height="200"/>
      <ImageModal src={imagesGuarda.mountain3} alt="mountain-3" height="200"/>
      <ImageModal src={imagesGuarda.mountain4} alt="mountain-4" height="200"/>
      <ImageModal src={imagesGuarda.mountain5} alt="mountain-5" height="200"/>
      <ImageModal src={imagesGuarda.mountain6} alt="mountain-6" height="200"/>
      <ImageModal src={imagesGuarda.mountain7} alt="mountain-7" height="200"/>
      <ImageModal src={imagesGuarda.mountain8} alt="mountain-8" height="200"/>
      <ImageModal src={imagesGuarda.mountain9} alt="mountain-9" height="200"/>
      <ImageModal src={imagesGuarda.mountain10} alt="mountain-10" height="200"/>
      <ImageModal src={imagesGuarda.steps1} alt="steps-1" height="200"/>
      <ImageModal src={imagesGuarda.stream1} alt="stream-1" height="200"/>
      <ImageModal src={imagesGuarda.street1} alt="street-1" height="200"/>
      <ImageModal src={imagesGuarda.street2} alt="street-2" height="200"/>
      <ImageModal src={imagesGuarda.window1} alt="window-1" height="200"/>
      <ImageModal src={imagesGuarda.window2} alt="window-2" height="200"/>
      <ImageModal src={imagesGuarda.window3} alt="window-3" height="200"/>

    </div>
  )
}
export default Guarda