import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Header from './components/Header';
import Home from './pages/Home';
import Abstract from './pages/Abstract';
import Europe from 'pages/Europe/Europe';
import Rome from './pages/Europe/Rome'; 
import Malta from 'pages/Europe/Malta';
import Bruges from 'pages/Europe/Bruges';
import Barcelona from 'pages/Europe/Barcelona';
import Colour from './pages/Colour';
import Wildlife from 'pages/Wildlife';
import Vancouver from 'pages/Vancouver';
import US from 'pages/US/US';
import Everglades from 'pages/US/Everglades';
// import NorthEast from 'pages/US/NorthEast';
import DC from 'pages/US/NorthEast/DC';
import NYC from 'pages/US/NorthEast/NYC';
import Philly from 'pages/US/NorthEast/Philly';
import SmokyMountains from 'pages/US/NorthEast/SmokyMountains';
import Miami from 'pages/US/Miami';
import Kew from 'pages/Kew';
import Vegas from 'pages/US/West/Vegas';
import Antelope from 'pages/US/West/Antelope';
import Horseshoe from 'pages/US/West/Horseshoe';
import Yosemite from 'pages/US/West/Yosemite';
import Zion from 'pages/US/West/Zion';
import Hoover from 'pages/US/West/Hoover';
import SR1 from 'pages/US/West/SR1';
import GrandCanyon from 'pages/US/West/GrandCanyon';
import Bryce from 'pages/US/West/Bryce';
import Moab from 'pages/US/West/Moab';
import DeathValley from 'pages/US/West/DeathValley';
import CH from 'pages/CH/CH';
import CHWinter from 'pages/CH/CHWinter';
import Engelberg from 'pages/CH/Engelberg';
import Guarda from 'pages/CH/Guarda';
import Lugano from 'pages/CH/Lugano';
import Matterhorn from 'pages/CH/Matterhorn';
import Mountains from 'pages/CH/Mountains';

function App() {
  return <>
    <Router>
      <div className="container">
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/abstract' element={<Abstract />} />
          <Route path='/europe' element={<Europe />} />
          <Route path='/rome' element={<Rome />} />
          <Route path='/malta' element={<Malta />} />
          <Route path='/bruges' element={<Bruges />} />
          <Route path='/barcelona' element={<Barcelona />} />
          <Route path='/colour' element={<Colour />} />
          <Route path='/wildlife' element={<Wildlife />} />
          <Route path='/vancouver' element={<Vancouver />} />
          <Route path='/us' element={<US />} />
          <Route path='/everglades' element={<Everglades />} />
          {/* <Route path='/northeast' element={<NorthEast />} /> */}
          <Route path='/dc' element={<DC />} />
          <Route path='/nyc' element={<NYC />} />
          <Route path='/philly' element={<Philly />} />
          <Route path='/smoky' element={<SmokyMountains />} />
          <Route path='/miami' element={<Miami />} />
          <Route path='/kew' element={<Kew />} />
          <Route path='/vegas' element={<Vegas />} />
          <Route path='/antelope' element={<Antelope />} />
          <Route path='/horseshoe' element={<Horseshoe />} />
          <Route path='/yosemite' element={<Yosemite />} />
          <Route path='/zion' element={<Zion />} />
          <Route path='/hoover' element={<Hoover />} />
          <Route path='/sr1' element={<SR1 />} />
          <Route path='/grandcanyon' element={<GrandCanyon />} />
          <Route path='/bryce' element={<Bryce />} />
          <Route path='/moab' element={<Moab />} />
          <Route path='/deathvalley' element={<DeathValley />} />
          <Route path='/ch' element={<CH />} />
          <Route path='/chwinter' element={<CHWinter />} />
          <Route path='/engelberg' element={<Engelberg />} />
          <Route path='/guarda' element={<Guarda />} />
          <Route path='/lugano' element={<Lugano />} />
          <Route path='/matterhorn' element={<Matterhorn />} />
          <Route path='/mountains' element={<Mountains />} />
        </Routes>
      </div>
    </Router>
  </>  
}

export default App;