import gornergrat1 from '../../../public/static/img/CH/matterhorn/gornergrat1.jpg'
import gornergrat2 from '../../../public/static/img/CH/matterhorn/gornergrat2.jpg'
import panorama1 from '../../../public/static/img/CH/matterhorn/panorama1.jpg'
import panorama2 from '../../../public/static/img/CH/matterhorn/panorama2.jpg'
import peak1 from '../../../public/static/img/CH/matterhorn/peak1.jpg'
import peak2 from '../../../public/static/img/CH/matterhorn/peak2.jpg'
import peak3 from '../../../public/static/img/CH/matterhorn/peak3.jpg'
import peak4 from '../../../public/static/img/CH/matterhorn/peak4.jpg'
import peak5 from '../../../public/static/img/CH/matterhorn/peak5.jpg'
import peak6 from '../../../public/static/img/CH/matterhorn/peak6.jpg'
import wall1 from '../../../public/static/img/CH/matterhorn/wall1.jpg'
import wall2 from '../../../public/static/img/CH/matterhorn/wall2.jpg'

export const imagesMatterhorn = {
  gornergrat1,
  gornergrat2,
  panorama1,
  panorama2,
  peak1,
  peak2,
  peak3,
  peak4,
  peak5,
  peak6,
  wall1,
  wall2
}