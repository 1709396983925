import anhinga1 from '../../../public/static/img/US/everglades/anhinga1.jpg'
import anhinga2 from '../../../public/static/img/US/everglades/anhinga2.jpg'
import anhinga3 from '../../../public/static/img/US/everglades/anhinga3.jpg'
import anhinga4 from '../../../public/static/img/US/everglades/anhinga4.jpg'
import anhinga5 from '../../../public/static/img/US/everglades/anhinga5.jpg'
import anhinga6 from '../../../public/static/img/US/everglades/anhinga6.jpg'
import anhinga7 from '../../../public/static/img/US/everglades/anhinga7.jpg'
import anhinga8 from '../../../public/static/img/US/everglades/anhinga8.jpg'
import anhinga9 from '../../../public/static/img/US/everglades/anhinga9.jpg'
import anhinga10 from '../../../public/static/img/US/everglades/anhinga10.jpg'
import anhinga11 from '../../../public/static/img/US/everglades/anhinga11.jpg'
import anhinga12 from '../../../public/static/img/US/everglades/anhinga12.jpg'
import butterfly1 from '../../../public/static/img/US/everglades/butterfly1.jpg'
import cormorant1 from '../../../public/static/img/US/everglades/cormorant1.jpg'
import cormorant2 from '../../../public/static/img/US/everglades/cormorant2.jpg'
import cormorant3 from '../../../public/static/img/US/everglades/cormorant3.jpg'
import cormorant4 from '../../../public/static/img/US/everglades/cormorant4.jpg'
import gator1 from '../../../public/static/img/US/everglades/gator1.jpg'
import gator2 from '../../../public/static/img/US/everglades/gator2.jpg'
import gator3 from '../../../public/static/img/US/everglades/gator3.jpg'
import gator4 from '../../../public/static/img/US/everglades/gator4.jpg'
import gator5 from '../../../public/static/img/US/everglades/gator5.jpg'
import gator6 from '../../../public/static/img/US/everglades/gator6.jpg'
import gator7 from '../../../public/static/img/US/everglades/gator7.jpg'
import gator8 from '../../../public/static/img/US/everglades/gator8.jpg'
import gator9 from '../../../public/static/img/US/everglades/gator9.jpg'
import gator10 from '../../../public/static/img/US/everglades/gator10.jpg'
import gator11 from '../../../public/static/img/US/everglades/gator11.jpg'
import gator12 from '../../../public/static/img/US/everglades/gator12.jpg'
import gator13 from '../../../public/static/img/US/everglades/gator13.jpg'
import gator14 from '../../../public/static/img/US/everglades/gator14.jpg'
import gator15 from '../../../public/static/img/US/everglades/gator15.jpg'
import gator16 from '../../../public/static/img/US/everglades/gator16.jpg'
import gator17 from '../../../public/static/img/US/everglades/gator17.jpg'
import gator18 from '../../../public/static/img/US/everglades/gator18.jpg'
import gator19 from '../../../public/static/img/US/everglades/gator19.jpg'
import gator20 from '../../../public/static/img/US/everglades/gator20.jpg'
import gator21 from '../../../public/static/img/US/everglades/gator21.jpg'
import gator22 from '../../../public/static/img/US/everglades/gator22.jpg'
import gator23 from '../../../public/static/img/US/everglades/gator23.jpg'
import gator24 from '../../../public/static/img/US/everglades/gator24.jpg'
import gator25 from '../../../public/static/img/US/everglades/gator25.jpg'
import gator26 from '../../../public/static/img/US/everglades/gator26.jpg'
import gator27 from '../../../public/static/img/US/everglades/gator27.jpg'
import gator28 from '../../../public/static/img/US/everglades/gator28.jpg'
import gator29 from '../../../public/static/img/US/everglades/gator29.jpg'
import gator30 from '../../../public/static/img/US/everglades/gator30.jpg'
import grasshopper1 from '../../../public/static/img/US/everglades/grasshopper1.jpg'
import grasshopper2 from '../../../public/static/img/US/everglades/grasshopper2.jpg'
import gull1 from '../../../public/static/img/US/everglades/gull1.jpg'
import gull2 from '../../../public/static/img/US/everglades/gull2.jpg'
import heron1 from '../../../public/static/img/US/everglades/heron1.jpg'
import heron2 from '../../../public/static/img/US/everglades/heron2.jpg'
import heron3 from '../../../public/static/img/US/everglades/heron3.jpg'
import heron4 from '../../../public/static/img/US/everglades/heron4.jpg'
import heron5 from '../../../public/static/img/US/everglades/heron5.jpg'
import heron6 from '../../../public/static/img/US/everglades/heron6.jpg'
import heron7 from '../../../public/static/img/US/everglades/heron7.jpg'
import heron8 from '../../../public/static/img/US/everglades/heron8.jpg'
import heron9 from '../../../public/static/img/US/everglades/heron9.jpg'
import heron10 from '../../../public/static/img/US/everglades/heron10.jpg'
import heron11 from '../../../public/static/img/US/everglades/heron11.jpg'
import heron12 from '../../../public/static/img/US/everglades/heron12.jpg'
import heron13 from '../../../public/static/img/US/everglades/heron13.jpg'
import ibis1 from '../../../public/static/img/US/everglades/ibis1.jpg'
import ibis2 from '../../../public/static/img/US/everglades/ibis2.jpg'
import ibis3 from '../../../public/static/img/US/everglades/ibis3.jpg'
import leaf1 from '../../../public/static/img/US/everglades/leaf1.jpg'
import leaf2 from '../../../public/static/img/US/everglades/leaf2.jpg'
import lizard1 from '../../../public/static/img/US/everglades/lizard1.jpg'
import lizard2 from '../../../public/static/img/US/everglades/lizard2.jpg'
import lizard3 from '../../../public/static/img/US/everglades/lizard3.jpg'
import lizard4 from '../../../public/static/img/US/everglades/lizard4.jpg'
import turtle1 from '../../../public/static/img/US/everglades/turtle1.jpg'
import turtle2 from '../../../public/static/img/US/everglades/turtle2.jpg'
import vulture1 from '../../../public/static/img/US/everglades/vulture1.jpg'
import vulture2 from '../../../public/static/img/US/everglades/vulture2.jpg'
import vulture3 from '../../../public/static/img/US/everglades/vulture3.jpg'
import walkway1 from '../../../public/static/img/US/everglades/walkway1.jpg'
import wood1 from '../../../public/static/img/US/everglades/wood1.jpg'

export const imagesEverglades = {
  anhinga1,
  anhinga2,
  anhinga3,
  anhinga4,
  anhinga5,
  anhinga6,
  anhinga7,
  anhinga8,
  anhinga9,
  anhinga10,
  anhinga11,
  anhinga12,
  butterfly1,
  cormorant1,
  cormorant2,
  cormorant3,
  cormorant4,
  gator1,
  gator2,
  gator3,
  gator4,
  gator5,
  gator6,
  gator7,
  gator8,
  gator9,
  gator10,
  gator11,
  gator12,
  gator13,
  gator14,
  gator15,
  gator16,
  gator17,
  gator18,
  gator19,
  gator20,
  gator21,
  gator22,
  gator23,
  gator24,
  gator25,
  gator26,
  gator27,
  gator28,
  gator29,
  gator30,
  grasshopper1,
  grasshopper2,
  gull1,
  gull2,
  heron1,
  heron2,
  heron3,
  heron4,
  heron5,
  heron6,
  heron7,
  heron8,
  heron9,
  heron10,
  heron11,
  heron12,
  heron13,
  ibis1,
  ibis2,
  ibis3,
  leaf1,
  leaf2,
  lizard1,
  lizard2,
  lizard3,
  lizard4,
  turtle1,
  turtle2,
  vulture1,
  vulture2,
  vulture3,
  walkway1,
  wood1
}