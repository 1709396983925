import aerial1 from '../../../../public/static/img/US/west/grandcanyon/aerial1.jpg'
import bison1 from '../../../../public/static/img/US/west/grandcanyon/bison1.jpg'
import butte1 from '../../../../public/static/img/US/west/grandcanyon/butte1.jpg'
import canyon1 from '../../../../public/static/img/US/west/grandcanyon/canyon1.jpg'
import canyon2 from '../../../../public/static/img/US/west/grandcanyon/canyon2.jpg'
import canyon3 from '../../../../public/static/img/US/west/grandcanyon/canyon3.jpg'
import canyon4 from '../../../../public/static/img/US/west/grandcanyon/canyon4.jpg'
import canyon5 from '../../../../public/static/img/US/west/grandcanyon/canyon5.jpg'
import canyon6 from '../../../../public/static/img/US/west/grandcanyon/canyon6.jpg'
import canyon7 from '../../../../public/static/img/US/west/grandcanyon/canyon7.jpg'
import canyon8 from '../../../../public/static/img/US/west/grandcanyon/canyon8.jpg'
import canyon9 from '../../../../public/static/img/US/west/grandcanyon/canyon9.jpg'
import canyon10 from '../../../../public/static/img/US/west/grandcanyon/canyon10.jpg'
import canyon11 from '../../../../public/static/img/US/west/grandcanyon/canyon11.jpg'
import canyon12 from '../../../../public/static/img/US/west/grandcanyon/canyon12.jpg'
import canyonbw1 from '../../../../public/static/img/US/west/grandcanyon/canyonbw1.jpg'
import canyonwall1 from '../../../../public/static/img/US/west/grandcanyon/canyonwall1.jpg'
import chopper1 from '../../../../public/static/img/US/west/grandcanyon/chopper1.jpg'
import clouds1 from '../../../../public/static/img/US/west/grandcanyon/clouds1.jpg'
import clouds2 from '../../../../public/static/img/US/west/grandcanyon/clouds2.jpg'
import clouds3 from '../../../../public/static/img/US/west/grandcanyon/clouds3.jpg'
import colorado1 from '../../../../public/static/img/US/west/grandcanyon/colorado1.jpg'
import colorado2 from '../../../../public/static/img/US/west/grandcanyon/colorado2.jpg'
import fisheyecanyon1 from '../../../../public/static/img/US/west/grandcanyon/fisheyecanyon1.jpg'
import lake1 from '../../../../public/static/img/US/west/grandcanyon/lake1.jpg'
import mine1 from '../../../../public/static/img/US/west/grandcanyon/mine1.jpg'
import sandstone1 from '../../../../public/static/img/US/west/grandcanyon/sandstone1.jpg'
import tree1 from '../../../../public/static/img/US/west/grandcanyon/tree1.jpg'
import tree2 from '../../../../public/static/img/US/west/grandcanyon/tree2.jpg'
import tree3 from '../../../../public/static/img/US/west/grandcanyon/tree3.jpg'

export const imagesGC = {
  aerial1,
  bison1,
  butte1,
  canyon1,
  canyon2,
  canyon3,
  canyon4,
  canyon5,
  canyon6,
  canyon7,
  canyon8,
  canyon9,
  canyon10,
  canyon11,
  canyon12,
  canyonbw1,
  canyonwall1,
  chopper1,
  clouds1,
  clouds2,
  clouds3,
  colorado1,
  colorado2,
  fisheyecanyon1,
  lake1,
  mine1,
  sandstone1,
  tree1,
  tree2,
  tree3
}