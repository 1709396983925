import birdseye1 from '../../../public/static/img/CH/engelberg/birdseye1.jpg'
import birdseye2 from '../../../public/static/img/CH/engelberg/birdseye2.jpg'
import birdseye3 from '../../../public/static/img/CH/engelberg/birdseye3.jpg'
import hahnensummer1 from '../../../public/static/img/CH/engelberg/hahnensummer1.jpg'
import hahnensummer2 from '../../../public/static/img/CH/engelberg/hahnensummer2.jpg'
import hahnensummer3 from '../../../public/static/img/CH/engelberg/hahnensummer3.jpg'
import hahnensummer4 from '../../../public/static/img/CH/engelberg/hahnensummer4.jpg'
import hahnensummer5 from '../../../public/static/img/CH/engelberg/hahnensummer5.jpg'
import hahnenwinter1 from '../../../public/static/img/CH/engelberg/hahnenwinter1.jpg'
import hahnenwinter2 from '../../../public/static/img/CH/engelberg/hahnenwinter2.jpg'
import hahnenwinter3 from '../../../public/static/img/CH/engelberg/hahnenwinter3.jpg'
import hahnenwinter4 from '../../../public/static/img/CH/engelberg/hahnenwinter4.jpg'
import hahnenwinter5 from '../../../public/static/img/CH/engelberg/hahnenwinter5.jpg'
import huts1 from '../../../public/static/img/CH/engelberg/huts1.jpg'
import huts2 from '../../../public/static/img/CH/engelberg/huts2.jpg'
import huts3 from '../../../public/static/img/CH/engelberg/huts3.jpg'
import trianglesummer1 from '../../../public/static/img/CH/engelberg/trianglesummer1.jpg'
import trianglewinter1 from '../../../public/static/img/CH/engelberg/trianglewinter1.jpg'
import trianglewinter2 from '../../../public/static/img/CH/engelberg/trianglewinter2.jpg'
import village1 from '../../../public/static/img/CH/engelberg/village1.jpg'
import village2 from '../../../public/static/img/CH/engelberg/village2.jpg'
import village3 from '../../../public/static/img/CH/engelberg/village3.jpg'

export const imagesEngelberg = {
  birdseye1,
  birdseye2,
  birdseye3,
  hahnensummer1,
  hahnensummer2,
  hahnensummer3,
  hahnensummer4,
  hahnensummer5,
  hahnenwinter1,
  hahnenwinter2,
  hahnenwinter3,
  hahnenwinter4,
  hahnenwinter5,
  huts1,
  huts2,
  huts3,
  trianglesummer1,
  trianglewinter1,
  trianglewinter2,
  village1,
  village2,
  village3
}