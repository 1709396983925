import { imagesRome } from '../../components/Europe/imagesRome'
import ImageModal from '../../components/ImageModal'

function Rome() {
  return (
    <div className="images">
      <ImageModal src={imagesRome.arch1} alt="arch-1" height="200"/>
      <ImageModal src={imagesRome.carrazione1} alt="carrazione-1" height="200"/>
      <ImageModal src={imagesRome.colosseum1} alt="colosseum-1" height="200"/>
      <ImageModal src={imagesRome.colosseum2} alt="colosseum-2" height="200"/>
      <ImageModal src={imagesRome.colosseum3} alt="colosseum-3" height="200"/>
      <ImageModal src={imagesRome.colosseum4} alt="colosseum-4" height="200"/>
      <ImageModal src={imagesRome.colosseum5} alt="colosseum-5" height="200"/>
      <ImageModal src={imagesRome.columns1} alt="columns-1" height="200"/>
      <ImageModal src={imagesRome.flag1} alt="flag-1" height="200"/>
      <ImageModal src={imagesRome.flag2} alt="flag-2" height="200"/>
      <ImageModal src={imagesRome.fountain1} alt="fountain-1" height="200"/>
      <ImageModal src={imagesRome.fountain2} alt="fountain-2" height="200"/>
      <ImageModal src={imagesRome.lion1} alt="lion-1" height="200"/>
      <ImageModal src={imagesRome.mirror1} alt="mirror-1" height="200"/>
      <ImageModal src={imagesRome.palantine1} alt="palantine-1" height="200"/>
      <ImageModal src={imagesRome.palantine2} alt="palantine-2" height="200"/>
      <ImageModal src={imagesRome.rotunda1} alt="rotunda-1" height="200"/>
      <ImageModal src={imagesRome.statue1} alt="statue-1" height="200"/>
      <ImageModal src={imagesRome.statue2} alt="statue-2" height="200"/>
      <ImageModal src={imagesRome.window1} alt="window-1" height="200"/>
    </div>
  )
}
export default Rome