import arch1 from '../../../public/static/img/Europe/rome/arch1.jpg'
import carrazione1 from '../../../public/static/img/Europe/rome/carrazione1.jpg'
import colosseum1 from '../../../public/static/img/Europe/rome/colosseum1.jpg'
import colosseum2 from '../../../public/static/img/Europe/rome/colosseum2.jpg'
import colosseum3 from '../../../public/static/img/Europe/rome/colosseum3.jpg'
import colosseum4 from '../../../public/static/img/Europe/rome/colosseum4.jpg'
import colosseum5 from '../../../public/static/img/Europe/rome/colosseum5.jpg'
import columns1 from '../../../public/static/img/Europe/rome/columns1.jpg'
import flag1 from '../../../public/static/img/Europe/rome/flag1.jpg'
import flag2 from '../../../public/static/img/Europe/rome/flag2.jpg'
import fountain1 from '../../../public/static/img/Europe/rome/fountain1.jpg'
import fountain2 from '../../../public/static/img/Europe/rome/fountain2.jpg'
import lion1 from '../../../public/static/img/Europe/rome/lion1.jpg'
import mirror1 from '../../../public/static/img/Europe/rome/mirror1.jpg'
import palantine1 from '../../../public/static/img/Europe/rome/palantine1.jpg'
import palantine2 from '../../../public/static/img/Europe/rome/palantine2.jpg'
import rotunda1 from '../../../public/static/img/Europe/rome/rotunda1.jpg'
import statue1 from '../../../public/static/img/Europe/rome/statue1.jpg'
import statue2 from '../../../public/static/img/Europe/rome/statue2.jpg'
import window1 from '../../../public/static/img/Europe/rome/window1.jpg'

export const imagesRome = {
  arch1,
  carrazione1,
  colosseum1,
  colosseum2,
  colosseum3,
  colosseum4,
  colosseum5,
  columns1,
  flag1,
  flag2,
  fountain1,
  fountain2,
  lion1,
  mirror1,
  palantine1,
  palantine2,
  rotunda1,
  statue1,
  statue2,
  window1
}