import { imagesKew } from '../components/imagesKew'
import ImageModal from '../components/ImageModal'

function Kew() {
  return (
    <div className="images">
      <ImageModal src={imagesKew.avenue1} alt="avenue-1" height="200"/>
      <ImageModal src={imagesKew.berries1} alt="berries-1" height="200"/>
      <ImageModal src={imagesKew.berries2} alt="berries-2" height="200"/>
      <ImageModal src={imagesKew.bridge1} alt="bridge-1" height="200"/>
      <ImageModal src={imagesKew.brownleaf1} alt="brownleaf-1" height="200"/>
      <ImageModal src={imagesKew.brownleaves1} alt="brownleaves-1" height="200"/>
      <ImageModal src={imagesKew.brownleaves2} alt="brownleaves-2" height="200"/>
      <ImageModal src={imagesKew.brownleaves3} alt="brownleaves-3" height="200"/>
      <ImageModal src={imagesKew.dandelion1} alt="dandelion-1" height="200"/>
      <ImageModal src={imagesKew.fallenleaves1} alt="fallenleaves-1" height="200"/>
      <ImageModal src={imagesKew.fallenleaves2} alt="fallenleaves-2" height="200"/>
      <ImageModal src={imagesKew.fallenleaves3} alt="fallenleaves-3" height="200"/>
      <ImageModal src={imagesKew.fallenleaves4} alt="fallenleaves-4" height="200"/>
      <ImageModal src={imagesKew.fountain1} alt="fountain-1" height="200"/>
      <ImageModal src={imagesKew.goose1} alt="goose-1" height="200"/>
      <ImageModal src={imagesKew.greenbrownleaves1} alt="greenbrownleaves-1" height="200"/>
      <ImageModal src={imagesKew.greenbrownleaves2} alt="greenbrownleaves-2" height="200"/>
      <ImageModal src={imagesKew.greenbrownleaves3} alt="greenbrownleaves-3" height="200"/>
      <ImageModal src={imagesKew.greenleaf1} alt="greenleaf-1" height="200"/>
      <ImageModal src={imagesKew.greenleaf2} alt="greenleaf-2" height="200"/>
      <ImageModal src={imagesKew.greenleaf3} alt="greenleaf-3" height="200"/>
      <ImageModal src={imagesKew.greenleaves1} alt="greenleaves-1" height="200"/>
      <ImageModal src={imagesKew.greenleaves2} alt="greenleaves-2" height="200"/>
      <ImageModal src={imagesKew.greenleaves3} alt="greenleaves-3" height="200"/>
      <ImageModal src={imagesKew.greenyellowleaves1} alt="greenyellowleaves-1" height="200"/>
      <ImageModal src={imagesKew.greenyellowleaves2} alt="greenyellowleaves-2" height="200"/>
      <ImageModal src={imagesKew.greenyellowleaves3} alt="greenyellowleaves-3" height="200"/>
      <ImageModal src={imagesKew.greenyellowleaves4} alt="greenyellowleaves-4" height="200"/>
      <ImageModal src={imagesKew.holly1} alt="holly-1" height="200"/>
      <ImageModal src={imagesKew.holly2} alt="holly-2" height="200"/>
      <ImageModal src={imagesKew.holly3} alt="holly-3" height="200"/>
      <ImageModal src={imagesKew.holly4} alt="holly-4" height="200"/>
      <ImageModal src={imagesKew.holly5} alt="holly-5" height="200"/>
      <ImageModal src={imagesKew.holly6} alt="holly-6" height="200"/>
      <ImageModal src={imagesKew.lake1} alt="lake-1" height="200"/>
      <ImageModal src={imagesKew.lake2} alt="lake-2" height="200"/>
      <ImageModal src={imagesKew.orangeleaves1} alt="orangeleaves-1" height="200"/>
      <ImageModal src={imagesKew.pagoda1} alt="pagoda-1" height="200"/>
      <ImageModal src={imagesKew.peacock1} alt="peacock-1" height="200"/>
      <ImageModal src={imagesKew.pinkflower1} alt="pinkflower-1" height="200"/>
      <ImageModal src={imagesKew.pinkflowers1} alt="pinkflowers-1" height="200"/>
      <ImageModal src={imagesKew.pinkflowers2} alt="pinkflowers-2" height="200"/>
      <ImageModal src={imagesKew.purpleflower1} alt="purpleflower-1" height="200"/>
      <ImageModal src={imagesKew.purpleflower2} alt="purpleflower-2" height="200"/>
      <ImageModal src={imagesKew.purpleflower3} alt="purpleflower-3" height="200"/>
      <ImageModal src={imagesKew.purpleflower4} alt="purpleflower-4" height="200"/>
      <ImageModal src={imagesKew.purpleflower5} alt="purpleflower-5" height="200"/>
      <ImageModal src={imagesKew.purpleflower6} alt="purpleflower-6" height="200"/>
      <ImageModal src={imagesKew.purpleflowers1} alt="purpleflowers-1" height="200"/>
      <ImageModal src={imagesKew.purpleflowers2} alt="purpleflowers-2" height="200"/>
      <ImageModal src={imagesKew.redflower1} alt="redflower-1" height="200"/>
      <ImageModal src={imagesKew.redgreenleaf1} alt="redgreenleaf-1" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves1} alt="redgreenleaves-1" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves2} alt="redgreenleaves-2" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves3} alt="redgreenleaves-3" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves4} alt="redgreenleaves-4" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves5} alt="redgreenleaves-5" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves6} alt="redgreenleaves-6" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves7} alt="redgreenleaves-7" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves8} alt="redgreenleaves-8" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves9} alt="redgreenleaves-9" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves10} alt="redgreenleaves-10" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves11} alt="redgreenleaves-11" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves12} alt="redgreenleaves-12" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves13} alt="redgreenleaves-13" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves14} alt="redgreenleaves-14" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves15} alt="redgreenleaves-15" height="200"/>
      <ImageModal src={imagesKew.redgreenleaves16} alt="redgreenleaves-16" height="200"/>
      <ImageModal src={imagesKew.redleaf1} alt="redleaf-1" height="200"/>
      <ImageModal src={imagesKew.redleaf2} alt="redleaf-2" height="200"/>
      <ImageModal src={imagesKew.redleaves1} alt="redleaves-1" height="200"/>
      <ImageModal src={imagesKew.redleaves2} alt="redleaves-2" height="200"/>
      <ImageModal src={imagesKew.redleaves3} alt="redleaves-3" height="200"/>
      <ImageModal src={imagesKew.redleaves4} alt="redleaves-4" height="200"/>
      <ImageModal src={imagesKew.redleaves5} alt="redleaves-5" height="200"/>
      <ImageModal src={imagesKew.redleaves6} alt="redleaves-6" height="200"/>
      <ImageModal src={imagesKew.redleaves7} alt="redleaves-7" height="200"/>
      <ImageModal src={imagesKew.redleaves8} alt="redleaves-8" height="200"/>
      <ImageModal src={imagesKew.redleaves9} alt="redleaves-9" height="200"/>
      <ImageModal src={imagesKew.redleaves10} alt="redleaves-10" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves1} alt="redyellowleaves-1" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves2} alt="redyellowleaves-2" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves3} alt="redyellowleaves-3" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves4} alt="redyellowleaves-4" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves5} alt="redyellowleaves-5" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves6} alt="redyellowleaves-6" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves7} alt="redyellowleaves-7" height="200"/>
      <ImageModal src={imagesKew.redyellowleaves8} alt="redyellowleaves-8" height="200"/>
      <ImageModal src={imagesKew.rosehips1} alt="rosehips-1" height="200"/>
      <ImageModal src={imagesKew.sky1} alt="sky-1" height="200"/>
      <ImageModal src={imagesKew.squirrel1} alt="squirrel-1" height="200"/>
      <ImageModal src={imagesKew.squirrel2} alt="squirrel-2" height="200"/>
      <ImageModal src={imagesKew.squirrel3} alt="squirrel-3" height="200"/>
      <ImageModal src={imagesKew.squirrel4} alt="squirrel-4" height="200"/>
      <ImageModal src={imagesKew.squirrel5} alt="squirrel-5" height="200"/>
      <ImageModal src={imagesKew.temple1} alt="temple-1" height="200"/>
      <ImageModal src={imagesKew.temple2} alt="temple-2" height="200"/>
      <ImageModal src={imagesKew.tree1} alt="tree-1" height="200"/>
      <ImageModal src={imagesKew.tree2} alt="tree-2" height="200"/>
      <ImageModal src={imagesKew.tree3} alt="tree-3" height="200"/>
      <ImageModal src={imagesKew.whiteflowers1} alt="whiteflowers-1" height="200"/>
      <ImageModal src={imagesKew.whiteflowers2} alt="whiteflowers-2" height="200"/>
      <ImageModal src={imagesKew.yellowleaf1} alt="yellowleaf-1" height="200"/>
      <ImageModal src={imagesKew.yellowleaves1} alt="yellowleaves-1" height="200"/>
      <ImageModal src={imagesKew.yellowleaves2} alt="yellowleaves-2" height="200"/>
    </div>
  )
}
export default Kew