import apartments1 from '../../../public/static/img/US/miami/apartments1.jpg'
import apartments2 from '../../../public/static/img/US/miami/apartments2.jpg'
import artdeco1 from '../../../public/static/img/US/miami/artdeco1.jpg'
import artdeco2 from '../../../public/static/img/US/miami/artdeco2.jpg'
import artdeco3 from '../../../public/static/img/US/miami/artdeco3.jpg'
import artdeco4 from '../../../public/static/img/US/miami/artdeco4.jpg'
import artdeco5 from '../../../public/static/img/US/miami/artdeco5.jpg'
import artdeco6 from '../../../public/static/img/US/miami/artdeco6.jpg'
import artdeco7 from '../../../public/static/img/US/miami/artdeco7.jpg'
import artdeco8 from '../../../public/static/img/US/miami/artdeco8.jpg'
import beach1 from '../../../public/static/img/US/miami/beach1.jpg'
import beach2 from '../../../public/static/img/US/miami/beach2.jpg'
import beach3 from '../../../public/static/img/US/miami/beach3.jpg'
import beach4 from '../../../public/static/img/US/miami/beach4.jpg'
import beach5 from '../../../public/static/img/US/miami/beach5.jpg'
import beach6 from '../../../public/static/img/US/miami/beach6.jpg'
import beach7 from '../../../public/static/img/US/miami/beach7.jpg'
import beach8 from '../../../public/static/img/US/miami/beach8.jpg'
import beach9 from '../../../public/static/img/US/miami/beach9.jpg'
import beach10 from '../../../public/static/img/US/miami/beach10.jpg'
import beach11 from '../../../public/static/img/US/miami/beach11.jpg'
import beach12 from '../../../public/static/img/US/miami/beach12.jpg'
import beach13 from '../../../public/static/img/US/miami/beach13.jpg'
import boatdive1 from '../../../public/static/img/US/miami/boatdive1.jpg'
import boatdive2 from '../../../public/static/img/US/miami/boatdive2.jpg'
import boatdive3 from '../../../public/static/img/US/miami/boatdive3.jpg'
import boatdive4 from '../../../public/static/img/US/miami/boatdive4.jpg'
import classic1 from '../../../public/static/img/US/miami/classic1.jpg'
import corvette1 from '../../../public/static/img/US/miami/corvette1.jpg'
import corvette2 from '../../../public/static/img/US/miami/corvette2.jpg'
import corvette3 from '../../../public/static/img/US/miami/corvette3.jpg'
import dock1 from '../../../public/static/img/US/miami/dock1.jpg'
import flight1 from '../../../public/static/img/US/miami/flight1.jpg'
import flight2 from '../../../public/static/img/US/miami/flight2.jpg'
import highway1 from '../../../public/static/img/US/miami/highway1.jpg'
import highway2 from '../../../public/static/img/US/miami/highway2.jpg'
import keywest1 from '../../../public/static/img/US/miami/keywest1.jpg'
import mustang1 from '../../../public/static/img/US/miami/mustang1.jpg'
import oceandrive1 from '../../../public/static/img/US/miami/oceandrive1.jpg'
import oceandrive2 from '../../../public/static/img/US/miami/oceandrive2.jpg'
import oceandrive3 from '../../../public/static/img/US/miami/oceandrive3.jpg'
import oceandrive4 from '../../../public/static/img/US/miami/oceandrive4.jpg'
import oceandrive5 from '../../../public/static/img/US/miami/oceandrive5.jpg'
import oceandrive6 from '../../../public/static/img/US/miami/oceandrive6.jpg'
import oceandrive7 from '../../../public/static/img/US/miami/oceandrive7.jpg'
import oceandrive8 from '../../../public/static/img/US/miami/oceandrive8.jpg'
import oceandrive9 from '../../../public/static/img/US/miami/oceandrive9.jpg'
import oceandrive10 from '../../../public/static/img/US/miami/oceandrive10.jpg'
import oceandrive11 from '../../../public/static/img/US/miami/oceandrive11.jpg'
import palm1 from '../../../public/static/img/US/miami/palm1.jpg'
import palm2 from '../../../public/static/img/US/miami/palm2.jpg'
import palm3 from '../../../public/static/img/US/miami/palm3.jpg'
import palm4 from '../../../public/static/img/US/miami/palm4.jpg'
import palm5 from '../../../public/static/img/US/miami/palm5.jpg'
import palm6 from '../../../public/static/img/US/miami/palm6.jpg'
import palm7 from '../../../public/static/img/US/miami/palm7.jpg'
import palm8 from '../../../public/static/img/US/miami/palm8.jpg'
import palm9 from '../../../public/static/img/US/miami/palm9.jpg'
import palm10 from '../../../public/static/img/US/miami/palm10.jpg'
import palm11 from '../../../public/static/img/US/miami/palm11.jpg'
import palm12 from '../../../public/static/img/US/miami/palm12.jpg'
import palm13 from '../../../public/static/img/US/miami/palm13.jpg'
import square1 from '../../../public/static/img/US/miami/square1.jpg'
import square2 from '../../../public/static/img/US/miami/square2.jpg'
import stairs1 from '../../../public/static/img/US/miami/stairs1.jpg'
import surf1 from '../../../public/static/img/US/miami/surf1.jpg'

export const imagesMiami = {
  apartments1,
  apartments2,
  artdeco1,
  artdeco2,
  artdeco3,
  artdeco4,
  artdeco5,
  artdeco6,
  artdeco7,
  artdeco8,
  beach1,
  beach2,
  beach3,
  beach4,
  beach5,
  beach6,
  beach7,
  beach8,
  beach9,
  beach10,
  beach11,
  beach12,
  beach13,
  boatdive1,
  boatdive2,
  boatdive3,
  boatdive4,
  classic1,
  corvette1,
  corvette2,
  corvette3,
  dock1,
  flight1,
  flight2,
  highway1,
  highway2,
  keywest1,
  mustang1,
  oceandrive1,
  oceandrive2,
  oceandrive3,
  oceandrive4,
  oceandrive5,
  oceandrive6,
  oceandrive7,
  oceandrive8,
  oceandrive9,
  oceandrive10,
  oceandrive11,
  palm1,
  palm2,
  palm3,
  palm4,
  palm5,
  palm6,
  palm7,
  palm8,
  palm9,
  palm10,
  palm11,
  palm12,
  palm13,
  square1,
  square2,
  stairs1,
  surf1
}