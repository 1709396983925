import camaro1 from '../../../../public/static/img/US/northeast/smokymountains/camaro1.jpg'
import fog1 from '../../../../public/static/img/US/northeast/smokymountains/fog1.jpg'
import mountains1 from '../../../../public/static/img/US/northeast/smokymountains/mountains1.jpg'
import mountains2 from '../../../../public/static/img/US/northeast/smokymountains/mountains2.jpg'
import mountains3 from '../../../../public/static/img/US/northeast/smokymountains/mountains3.jpg'
import mountains4 from '../../../../public/static/img/US/northeast/smokymountains/mountains4.jpg'

export const imagesSmoky = {
  camaro1,
  fog1,
  mountains1,
  mountains2,
  mountains3,
  mountains4
}