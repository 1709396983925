import { imagesMatterhorn } from '../../components/CH/imagesMatterhorn'
import ImageModal from '../../components/ImageModal'

function Matterhorn() {
  return (
    <div className="images">
      <ImageModal src={imagesMatterhorn.gornergrat1} alt="gornergrat-1" height="200"/>
      <ImageModal src={imagesMatterhorn.gornergrat2} alt="gornergrat-2" height="200"/>
      <ImageModal src={imagesMatterhorn.panorama1} alt="panorama-1" height="200"/>
      <ImageModal src={imagesMatterhorn.peak1} alt="peak-1" height="200"/>
      <ImageModal src={imagesMatterhorn.peak2} alt="peak-2" height="200"/>
      <ImageModal src={imagesMatterhorn.peak3} alt="peak-3" height="200"/>
      <ImageModal src={imagesMatterhorn.peak4} alt="peak-4" height="200"/>
      <ImageModal src={imagesMatterhorn.peak5} alt="peak-5" height="200"/>
      <ImageModal src={imagesMatterhorn.peak6} alt="peak-6" height="200"/>
      <ImageModal src={imagesMatterhorn.wall1} alt="wall-1" height="200"/>
      <ImageModal src={imagesMatterhorn.wall2} alt="wall-2" height="200"/>
      <ImageModal src={imagesMatterhorn.panorama2} alt="panorama-2" height="200"/>

    </div>
  )
}
export default Matterhorn