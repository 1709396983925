import chrysler1 from '../../../../public/static/img/US/northeast/nyc/chrysler1.jpg'
import chrysler2 from '../../../../public/static/img/US/northeast/nyc/chrysler2.jpg'
import chrysler3 from '../../../../public/static/img/US/northeast/nyc/chrysler3.jpg'
import chrysler4 from '../../../../public/static/img/US/northeast/nyc/chrysler4.jpg'
import chrysler5 from '../../../../public/static/img/US/northeast/nyc/chrysler5.jpg'
import chrysler6 from '../../../../public/static/img/US/northeast/nyc/chrysler6.jpg'
import chrysler7 from '../../../../public/static/img/US/northeast/nyc/chrysler7.jpg'
import empire1 from '../../../../public/static/img/US/northeast/nyc/empire1.jpg'
import empire2 from '../../../../public/static/img/US/northeast/nyc/empire2.jpg'
import empire3 from '../../../../public/static/img/US/northeast/nyc/empire3.jpg'
import empire4 from '../../../../public/static/img/US/northeast/nyc/empire4.jpg'
import empire5 from '../../../../public/static/img/US/northeast/nyc/empire5.jpg'
import empire6 from '../../../../public/static/img/US/northeast/nyc/empire6.jpg'
import flatiron1 from '../../../../public/static/img/US/northeast/nyc/flatiron1.jpg'
import liberty1 from '../../../../public/static/img/US/northeast/nyc/liberty1.jpg'
import madison1 from '../../../../public/static/img/US/northeast/nyc/madison1.jpg'
import pigeon1 from '../../../../public/static/img/US/northeast/nyc/pigeon1.jpg'
import skyline1 from '../../../../public/static/img/US/northeast/nyc/skyline1.jpg'
import skyline2 from '../../../../public/static/img/US/northeast/nyc/skyline2.jpg'
import skyline3 from '../../../../public/static/img/US/northeast/nyc/skyline3.jpg'
import skyline4 from '../../../../public/static/img/US/northeast/nyc/skyline4.jpg'
import skyline5 from '../../../../public/static/img/US/northeast/nyc/skyline5.jpg'
import skyline6 from '../../../../public/static/img/US/northeast/nyc/skyline6.jpg'
import skyline7 from '../../../../public/static/img/US/northeast/nyc/skyline7.jpg'
import skyline8 from '../../../../public/static/img/US/northeast/nyc/skyline8.jpg'
import skyline9 from '../../../../public/static/img/US/northeast/nyc/skyline9.jpg'
import skyline10 from '../../../../public/static/img/US/northeast/nyc/skyline10.jpg'
import skyline11 from '../../../../public/static/img/US/northeast/nyc/skyline11.jpg'
import skyline12 from '../../../../public/static/img/US/northeast/nyc/skyline12.jpg'
import skyline13 from '../../../../public/static/img/US/northeast/nyc/skyline13.jpg'
import skyline14 from '../../../../public/static/img/US/northeast/nyc/skyline14.jpg'
import skyline15 from '../../../../public/static/img/US/northeast/nyc/skyline15.jpg'
import skyline16 from '../../../../public/static/img/US/northeast/nyc/skyline16.jpg'
import skyline17 from '../../../../public/static/img/US/northeast/nyc/skyline17.jpg'
import skyline18 from '../../../../public/static/img/US/northeast/nyc/skyline18.jpg'
import skyline19 from '../../../../public/static/img/US/northeast/nyc/skyline19.jpg'
import skyline20 from '../../../../public/static/img/US/northeast/nyc/skyline20.jpg'
import skyline21 from '../../../../public/static/img/US/northeast/nyc/skyline21.jpg'
import tower1 from '../../../../public/static/img/US/northeast/nyc/tower1.jpg'
import tower2 from '../../../../public/static/img/US/northeast/nyc/tower2.jpg'
import trump1 from '../../../../public/static/img/US/northeast/nyc/trump1.jpg'
import unitednations1 from '../../../../public/static/img/US/northeast/nyc/unitednations1.jpg'
import unitednations2 from '../../../../public/static/img/US/northeast/nyc/unitednations2.jpg'

export const imagesNYC = {
  chrysler1,
  chrysler2,
  chrysler3,
  chrysler4,
  chrysler5,
  chrysler6,
  chrysler7,
  empire1,
  empire2,
  empire3,
  empire4,
  empire5,
  empire6,
  flatiron1,
  liberty1,
  madison1,
  pigeon1,
  skyline1,
  skyline2,
  skyline3,
  skyline4,
  skyline5,
  skyline6,
  skyline7,
  skyline8,
  skyline9,
  skyline10,
  skyline11,
  skyline12,
  skyline13,
  skyline14,
  skyline15,
  skyline16,
  skyline17,
  skyline18,
  skyline19,
  skyline20,
  skyline21,
  tower1,
  tower2,
  trump1,
  unitednations1,
  unitednations2
}