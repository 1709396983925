import castle1 from '../../../public/static/img/CH/guarda/castle1.jpg'
import door1 from '../../../public/static/img/CH/guarda/door1.jpg'
import door2 from '../../../public/static/img/CH/guarda/door2.jpg'
import fountain1 from '../../../public/static/img/CH/guarda/fountain1.jpg'
import house1 from '../../../public/static/img/CH/guarda/house1.jpg'
import lawn1 from '../../../public/static/img/CH/guarda/lawn1.jpg'
import mountain1 from '../../../public/static/img/CH/guarda/mountain1.jpg'
import mountain2 from '../../../public/static/img/CH/guarda/mountain2.jpg'
import mountain3 from '../../../public/static/img/CH/guarda/mountain3.jpg'
import mountain4 from '../../../public/static/img/CH/guarda/mountain4.jpg'
import mountain5 from '../../../public/static/img/CH/guarda/mountain5.jpg'
import mountain6 from '../../../public/static/img/CH/guarda/mountain6.jpg'
import mountain7 from '../../../public/static/img/CH/guarda/mountain7.jpg'
import mountain8 from '../../../public/static/img/CH/guarda/mountain8.jpg'
import mountain9 from '../../../public/static/img/CH/guarda/mountain9.jpg'
import mountain10 from '../../../public/static/img/CH/guarda/mountain10.jpg'
import steps1 from '../../../public/static/img/CH/guarda/steps1.jpg'
import stream1 from '../../../public/static/img/CH/guarda/stream1.jpg'
import street1 from '../../../public/static/img/CH/guarda/street1.jpg'
import street2 from '../../../public/static/img/CH/guarda/street2.jpg'
import window1 from '../../../public/static/img/CH/guarda/window1.jpg'
import window2 from '../../../public/static/img/CH/guarda/window2.jpg'
import window3 from '../../../public/static/img/CH/guarda/window3.jpg'

export const imagesGuarda = {
  castle1,
  door1,
  door2,
  fountain1,
  house1,
  lawn1,
  mountain1,
  mountain2,
  mountain3,
  mountain4,
  mountain5,
  mountain6,
  mountain7,
  mountain8,
  mountain9,
  mountain10,
  steps1,
  stream1,
  street1,
  street2,
  window1,
  window2,
  window3
}