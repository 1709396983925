import { NavLink } from "react-router-dom"

function Header() {
  return (
    <header className="header">
      <div className="header-container">
        <div className="header-title">
          <h1>Photo Gallery</h1>
        </div>
        <div className="links">
          <div className="logo">
            <NavLink to='/' className={({isActive}) => (isActive ? "active" : null)}>Home</NavLink> 
          </div>
          <div className="link-container">
            <ul className="sub-links">
              <>
                <li>
                  <NavLink to='/abstract' className={({isActive}) => (isActive ? "active" : null)}>
                    Abstract
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/europe' className={({isActive}) => (isActive ? "active" : null)}>
                    Europe
                  </NavLink>
                </li>        
                <li>
                  <NavLink to='/colour' className={({isActive}) => (isActive ? "active" : null)}>
                    Colour
                  </NavLink>
                </li>        
                <li>
                  <NavLink to='/wildlife' className={({isActive}) => (isActive ? "active" : null)}>
                    Wildlife
                  </NavLink>
                </li>        
                <li>
                  <NavLink to='/vancouver' className={({isActive}) => (isActive ? "active" : null)}>
                    Vancouver
                  </NavLink>
                </li>        
                <li>
                  <NavLink to='/us' className={({isActive}) => (isActive ? "active" : null)}>
                    US
                  </NavLink>
                </li>        
                <li>
                  <NavLink to='/kew' className={({isActive}) => (isActive ? "active" : null)}>
                    Kew
                  </NavLink>
                </li>                
                <li>
                  <NavLink to='/ch' className={({isActive}) => (isActive ? "active" : null)}>
                    CH
                  </NavLink>
                </li>
              </>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
