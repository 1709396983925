import bench2 from '../../public/static/img/abstract/bench2.jpg' 
import statue1 from '../../public/static/img/Europe/rome/statue1.jpg'
import lion2 from '../../public/static/img/Europe/malta/lion2.jpg'
import faces1 from '../../public/static/img/Europe/bruges/faces1.jpg'
import door1 from '../../public/static/img/Europe/barcelona/door1.jpg'
import blue2 from '../../public/static/img/colour/blue2.jpg'
import antelope1 from '../../public/static/img/wildlife/antelope1.jpg'
import tower1 from '../../public/static/img/vancouver/tower1.jpg'
import gator20 from '../../public/static/img/US/everglades/gator20.jpg'
import memorial2 from '../../public/static/img/US/northeast/dc/memorial2.jpg'
import chrysler4 from '../../public/static/img/US/northeast/nyc/chrysler4.jpg'
import phillyskyline2 from '../../public/static/img/US/northeast/philly/phillyskyline2.jpg'
import mountains2 from '../../public/static/img/US/northeast/smokymountains/mountains2.jpg'
import artdeco2 from '../../public/static/img/US/miami/artdeco2.jpg'
import avenue1 from '../../public/static/img/kew/avenue1.jpg'
import strat2 from '../../public/static/img/US/west/vegas/strat2.jpg'
import bwantelope5 from '../../public/static/img/US/west/antelope/bwantelope5.jpg'
import horseshoe2 from '../../public/static/img/US/west/horseshoe/horseshoe2.jpg'
import halfdome5 from '../../public/static/img/US/west/yosemite/halfdome5.jpg'
import mountains7 from '../../public/static/img/US/west/zion/mountains7.jpg'
import fulldam3 from '../../public/static/img/US/west/hoover/fulldam3.jpg'
import coast10 from '../../public/static/img/US/west/sr1/coast10.jpg'
import tree3 from '../../public/static/img/US/west/grandcanyon/tree3.jpg'
import hoodoo3 from '../../public/static/img/US/west/bryce/hoodoo3.jpg'
import arch3 from '../../public/static/img/US/west/moab/arch3.jpg'
import zabriskie5 from '../../public/static/img/US/west/dv/zabriskie5.jpg'
import bwforest3 from '../../public/static/img/CH/winter/bwforest3.jpg'
import huts1 from '../../public/static/img/CH/engelberg/huts1.jpg'
import montebre3 from '../../public/static/img/CH/lugano/montebre3.jpg'
import peak2 from '../../public/static/img/CH/matterhorn/peak2.jpg'
import mountain9 from '../../public/static/img/CH/guarda/mountain9.jpg'
import vista25 from '../../public/static/img/CH/summer/mountains/vista25.jpg'

import Image from '../components/Image'

import { Link } from "react-router-dom"

function Home() {
  return (
    <div className='images imagesHome'>
      <div className="linkImage">
        <Link to='/abstract'>
          <Image src={bench2} alt="abstract" height="200"/>
          <p className="imageLabel">Abstract</p>
        </Link>       
      </div>
      <div className="linkImage">
        <Link to='/colour'>
          <Image src={blue2} alt="colour" height="200"/>
          <p className="imageLabel">Colour</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/wildlife'>
          <Image src={antelope1} alt="wildlife" height="200"/>
          <p className="imageLabel">Wildlife</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/rome'>
          <Image src={statue1} alt="rome" height="200"/>
          <p className="imageLabel">Rome</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/malta'>
          <Image src={lion2} alt="malta" height="200"/>
          <p className="imageLabel">Malta</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/bruges'>
          <Image src={faces1} alt="bruges" height="200"/>
          <p className="imageLabel">Bruges</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/barcelona'>
          <Image src={door1} alt="barcelona" height="200"/>
          <p className="imageLabel">Barcelona</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/vancouver'>
          <Image src={tower1} alt="vancouver" height="200"/>
          <p className="imageLabel">Vancouver</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/kew'>
          <Image src={avenue1} alt="kew" height="200"/>
          <p className="imageLabel">Kew</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/everglades'>
          <Image src={gator20} alt="everglades" height="200"/>
          <p className="imageLabel">Everglades</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/miami'>
          <Image src={artdeco2} alt="miami" height="200"/>
          <p className="imageLabel">Miami</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/dc'>
          <Image src={memorial2} alt="dc" height="200"/>
          <p className="imageLabel">Washington DC</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/philly'>
          <Image src={phillyskyline2} alt="philly" height="200"/>
          <p className="imageLabel">Philadelphia</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/nyc'>
          <Image src={chrysler4} alt="nyc" height="200"/>
          <p className="imageLabel">New York</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/smoky'>
          <Image src={mountains2} alt="smoky" height="200"/>
          <p className="imageLabel">Smoky Mountains</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/vegas'>
          <Image src={strat2} alt="vegas" height="200"/>
          <p className="imageLabel">Las Vegas</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/hoover'>
          <Image src={fulldam3} alt="hoover" height="200"/>
          <p className="imageLabel">Hoover Dam</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/antelope'>
          <Image src={bwantelope5} alt="antelope" height="200"/>
          <p className="imageLabel">Antelope Canyon</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/horseshoe'>
          <Image src={horseshoe2} alt="horseshoe" height="200"/>
          <p className="imageLabel">Horseshoe Bend</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/yosemite'>
          <Image src={halfdome5} alt="yosemite" height="200"/>
          <p className="imageLabel">Yosemite</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/zion'>
          <Image src={mountains7} alt="zion" height="200"/>
          <p className="imageLabel">Zion Canyon</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/sr1'>
          <Image src={coast10} alt="sr1" height="200"/>
          <p className="imageLabel">State Route 1</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/grandcanyon'>
          <Image src={tree3} alt="grandcanyon" height="200"/>
          <p className="imageLabel">Grand Canyon</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/bryce'>
          <Image src={hoodoo3} alt="bryce" height="200"/>
          <p className="imageLabel">Bryce Canyon</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/moab'>
          <Image src={arch3} alt="moab" height="200"/>
          <p className="imageLabel">Arches & Canyonlands</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/deathvalley'>
          <Image src={zabriskie5} alt="deathvalley" height="200"/>
          <p className="imageLabel">Death Valley</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/engelberg'>
          <Image src={huts1} alt="engelberg" height="200"/>
          <p className="imageLabel">Engelberg</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/lugano'>
          <Image src={montebre3} alt="lugano" height="200"/>
          <p className="imageLabel">Lugano</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/guarda'>
          <Image src={mountain9} alt="guarda" height="200"/>
          <p className="imageLabel">Guarda</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/matterhorn'>
          <Image src={peak2} alt="matterhorn" height="200"/>
          <p className="imageLabel">Matterhorn</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/mountains'>
          <Image src={vista25} alt="mountains" height="200"/>
          <p className="imageLabel">Mountains</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/chwinter'>
          <Image src={bwforest3} alt="chwinter" height="200"/>
          <p className="imageLabel">Winter</p>
        </Link>
      </div>
    </div>
  )
}
export default Home