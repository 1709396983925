import butte1 from '../../../../public/static/img/US/west/bryce/butte1.jpg'
import bwclouds1 from '../../../../public/static/img/US/west/bryce/bwclouds1.jpg'
import bwhoodoo1 from '../../../../public/static/img/US/west/bryce/bwhoodoo1.jpg'
import bwhoodoo2 from '../../../../public/static/img/US/west/bryce/bwhoodoo2.jpg'
import bwhorizon1 from '../../../../public/static/img/US/west/bryce/bwhorizon1.jpg'
import hoodoo1 from '../../../../public/static/img/US/west/bryce/hoodoo1.jpg'
import hoodoo2 from '../../../../public/static/img/US/west/bryce/hoodoo2.jpg'
import hoodoo3 from '../../../../public/static/img/US/west/bryce/hoodoo3.jpg'
import hoodoo4 from '../../../../public/static/img/US/west/bryce/hoodoo4.jpg'
import hoodoo5 from '../../../../public/static/img/US/west/bryce/hoodoo5.jpg'
import hoodoo6 from '../../../../public/static/img/US/west/bryce/hoodoo6.jpg'
import hoodoo7 from '../../../../public/static/img/US/west/bryce/hoodoo7.jpg'
import hoodoo8 from '../../../../public/static/img/US/west/bryce/hoodoo8.jpg'
import hoodoo9 from '../../../../public/static/img/US/west/bryce/hoodoo9.jpg'
import horizon1 from '../../../../public/static/img/US/west/bryce/horizon1.jpg'
import horizon2 from '../../../../public/static/img/US/west/bryce/horizon2.jpg'
import horizon3 from '../../../../public/static/img/US/west/bryce/horizon3.jpg'
import horizon4 from '../../../../public/static/img/US/west/bryce/horizon4.jpg'
import horizon5 from '../../../../public/static/img/US/west/bryce/horizon5.jpg'
import valley1 from '../../../../public/static/img/US/west/bryce/valley1.jpg'

export const imagesBryce = {
  butte1,
  bwclouds1,
  bwhoodoo1,
  bwhoodoo2,
  bwhorizon1,
  hoodoo1,
  hoodoo2,
  hoodoo3,
  hoodoo4,
  hoodoo5,
  hoodoo6,
  hoodoo7,
  hoodoo8,
  hoodoo9,
  horizon1,
  horizon2,
  horizon3,
  horizon4,
  horizon5,
  valley1
}