import { imagesZion } from '../../../components/US/West/imagesZion'
import ImageModal from '../../../components/ImageModal'

function Zion() {
  return (
    <div className="images">
      <ImageModal src={imagesZion.bwwall1} alt="bwwall-1" height="200"/>
      <ImageModal src={imagesZion.bwwall2} alt="bwwall-2" height="200"/>
      <ImageModal src={imagesZion.horizon1} alt="horizon-1" height="200"/>
      <ImageModal src={imagesZion.mountains1} alt="mountains-1" height="200"/>
      <ImageModal src={imagesZion.mountains2} alt="mountains-2" height="200"/>
      <ImageModal src={imagesZion.mountains3} alt="mountains-3" height="200"/>
      <ImageModal src={imagesZion.mountains4} alt="mountains-4" height="200"/>
      <ImageModal src={imagesZion.mountains5} alt="mountains-5" height="200"/>
      <ImageModal src={imagesZion.mountains6} alt="mountains-6" height="200"/>
      <ImageModal src={imagesZion.mountains7} alt="mountains-7" height="200"/>
      <ImageModal src={imagesZion.mountains8} alt="mountains-8" height="200"/>
      <ImageModal src={imagesZion.mountains9} alt="mountains-9" height="200"/>
      <ImageModal src={imagesZion.mountains10} alt="mountains-10" height="200"/>
      <ImageModal src={imagesZion.path1} alt="path-1" height="200"/>
      <ImageModal src={imagesZion.river1} alt="river-1" height="200"/>
      <ImageModal src={imagesZion.river2} alt="river-2" height="200"/>
      <ImageModal src={imagesZion.valley1} alt="valley-1" height="200"/>
      <ImageModal src={imagesZion.wall1} alt="wall-1" height="200"/>
      <ImageModal src={imagesZion.wall2} alt="wall-2" height="200"/>
      <ImageModal src={imagesZion.wall3} alt="wall-3" height="200"/>
      <ImageModal src={imagesZion.yellowflower1} alt="yellowflower-1" height="200"/>

    </div>
  )
}
export default Zion