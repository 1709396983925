import { imagesSmoky } from '../../../components/US/NorthEast/imagesSmoky'
import ImageModal from '../../../components/ImageModal'

function SmokyMountains() {
  return (
    <div className="images">
      <ImageModal src={imagesSmoky.camaro1} alt="camaro-1" height="200"/>
      <ImageModal src={imagesSmoky.fog1} alt="fog-1" height="200"/>
      <ImageModal src={imagesSmoky.mountains1} alt="mountains-1" height="200"/>
      <ImageModal src={imagesSmoky.mountains2} alt="mountains-2" height="200"/>
      <ImageModal src={imagesSmoky.mountains3} alt="mountains-3" height="200"/>
      <ImageModal src={imagesSmoky.mountains4} alt="mountains-4" height="200"/>
    </div>
  )
}
export default SmokyMountains