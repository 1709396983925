import { imagesEngelberg } from '../../components/CH/imagesEngelberg'
import ImageModal from '../../components/ImageModal'

function Engelberg() {
  return (
    <div className="images">
      <ImageModal src={imagesEngelberg.birdseye1} alt="birdseye-1" height="200"/>
      <ImageModal src={imagesEngelberg.birdseye2} alt="birdseye-2" height="200"/>
      <ImageModal src={imagesEngelberg.birdseye3} alt="birdseye-3" height="200"/>
      <ImageModal src={imagesEngelberg.hahnensummer1} alt="hahnensummer-1" height="200"/>
      <ImageModal src={imagesEngelberg.hahnensummer2} alt="hahnensummer-2" height="200"/>
      <ImageModal src={imagesEngelberg.hahnensummer3} alt="hahnensummer-3" height="200"/>
      <ImageModal src={imagesEngelberg.hahnensummer4} alt="hahnensummer-4" height="200"/>
      <ImageModal src={imagesEngelberg.hahnensummer5} alt="hahnensummer-5" height="200"/>
      <ImageModal src={imagesEngelberg.hahnenwinter1} alt="hahnenwinter-1" height="200"/>
      <ImageModal src={imagesEngelberg.hahnenwinter2} alt="hahnenwinter-2" height="200"/>
      <ImageModal src={imagesEngelberg.hahnenwinter3} alt="hahnenwinter-3" height="200"/>
      <ImageModal src={imagesEngelberg.hahnenwinter4} alt="hahnenwinter-4" height="200"/>
      <ImageModal src={imagesEngelberg.hahnenwinter5} alt="hahnenwinter-5" height="200"/>
      <ImageModal src={imagesEngelberg.huts1} alt="huts-1" height="200"/>
      <ImageModal src={imagesEngelberg.huts2} alt="huts-2" height="200"/>
      <ImageModal src={imagesEngelberg.huts3} alt="huts-3" height="200"/>
      <ImageModal src={imagesEngelberg.trianglesummer1} alt="trianglesummer-1" height="200"/>
      <ImageModal src={imagesEngelberg.trianglewinter1} alt="trianglewinter-1" height="200"/>
      <ImageModal src={imagesEngelberg.trianglewinter2} alt="trianglewinter-2" height="200"/>
      <ImageModal src={imagesEngelberg.village1} alt="village-1" height="200"/>
      <ImageModal src={imagesEngelberg.village2} alt="village-2" height="200"/>
      <ImageModal src={imagesEngelberg.village3} alt="village-3" height="200"/>
    </div>
  )
}
export default Engelberg