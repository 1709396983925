import aerial1 from '../../../public/static/img/Europe/bruges/aerial1.jpg'
import babel1 from '../../../public/static/img/Europe/bruges/babel1.jpg'
import bridge1 from '../../../public/static/img/Europe/bruges/bridge1.jpg'
import bridge2 from '../../../public/static/img/Europe/bruges/bridge2.jpg'
import bridge3 from '../../../public/static/img/Europe/bruges/bridge3.jpg'
import bridge4 from '../../../public/static/img/Europe/bruges/bridge4.jpg'
import bridge5 from '../../../public/static/img/Europe/bruges/bridge5.jpg'
import buttress1 from '../../../public/static/img/Europe/bruges/buttress1.jpg'
import canal1 from '../../../public/static/img/Europe/bruges/canal1.jpg'
import canal2 from '../../../public/static/img/Europe/bruges/canal2.jpg'
import canal3 from '../../../public/static/img/Europe/bruges/canal3.jpg'
import fabric1 from '../../../public/static/img/Europe/bruges/fabric1.jpg'
import faces1 from '../../../public/static/img/Europe/bruges/faces1.jpg'
import leaf1 from '../../../public/static/img/Europe/bruges/leaf1.jpg'
import mural1 from '../../../public/static/img/Europe/bruges/mural1.jpg'
import reflection1 from '../../../public/static/img/Europe/bruges/reflection1.jpg'
import reflection2 from '../../../public/static/img/Europe/bruges/reflection2.jpg'
import sculpture1 from '../../../public/static/img/Europe/bruges/sculpture1.jpg'
import spire1 from '../../../public/static/img/Europe/bruges/spire1.jpg'
import swan1 from '../../../public/static/img/Europe/bruges/swan1.jpg'
import tower1 from '../../../public/static/img/Europe/bruges/tower1.jpg'
import trails1 from '../../../public/static/img/Europe/bruges/trails1.jpg'

export const imagesBruges = {
  aerial1,
  babel1,
  bridge1,
  bridge2,
  bridge3,
  bridge4,
  bridge5,
  buttress1,
  canal1,
  canal2,
  canal3,
  fabric1,
  faces1,
  leaf1,
  mural1,
  reflection1,
  reflection2,
  sculpture1,
  spire1,
  swan1,
  tower1,
  trails1
}