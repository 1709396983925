import arch1 from '../../../../public/static/img/US/west/moab/arch1.jpg'
import arch2 from '../../../../public/static/img/US/west/moab/arch2.jpg'
import arch3 from '../../../../public/static/img/US/west/moab/arch3.jpg'
import arch4 from '../../../../public/static/img/US/west/moab/arch4.jpg'
import bwtree1 from '../../../../public/static/img/US/west/moab/bwtree1.jpg'
import bwtree2 from '../../../../public/static/img/US/west/moab/bwtree2.jpg'
import bwtree3 from '../../../../public/static/img/US/west/moab/bwtree3.jpg'
import canyonlands1 from '../../../../public/static/img/US/west/moab/canyonlands1.jpg'
import canyonlands2 from '../../../../public/static/img/US/west/moab/canyonlands2.jpg'
import canyonlands3 from '../../../../public/static/img/US/west/moab/canyonlands3.jpg'
import canyonlands4 from '../../../../public/static/img/US/west/moab/canyonlands4.jpg'
import cliffs1 from '../../../../public/static/img/US/west/moab/cliffs1.jpg'
import cliffs2 from '../../../../public/static/img/US/west/moab/cliffs2.jpg'
import cliffs3 from '../../../../public/static/img/US/west/moab/cliffs3.jpg'
import highway1 from '../../../../public/static/img/US/west/moab/highway1.jpg'
import highway2 from '../../../../public/static/img/US/west/moab/highway2.jpg'
import hoodoo1 from '../../../../public/static/img/US/west/moab/hoodoo1.jpg'
import horizon1 from '../../../../public/static/img/US/west/moab/horizon1.jpg'
import horizon2 from '../../../../public/static/img/US/west/moab/horizon2.jpg'
import horizon3 from '../../../../public/static/img/US/west/moab/horizon3.jpg'
import river1 from '../../../../public/static/img/US/west/moab/river1.jpg'
import scrub1 from '../../../../public/static/img/US/west/moab/scrub1.jpg'
import tree1 from '../../../../public/static/img/US/west/moab/tree1.jpg'

export const imagesMoab = {
  arch1,
  arch2,
  arch3,
  arch4,
  bwtree1,
  bwtree2,
  bwtree3,
  canyonlands1,
  canyonlands2,
  canyonlands3,
  canyonlands4,
  cliffs1,
  cliffs2,
  cliffs3,
  highway1,
  highway2,
  hoodoo1,
  horizon1,
  horizon2,
  horizon3,
  river1,
  scrub1,
  tree1
}