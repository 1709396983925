import avenue1 from '../../public/static/img/kew/avenue1.jpg'
import berries1 from '../../public/static/img/kew/berries1.jpg'
import berries2 from '../../public/static/img/kew/berries2.jpg'
import bridge1 from '../../public/static/img/kew/bridge1.jpg'
import brownleaf1 from '../../public/static/img/kew/brownleaf1.jpg'
import brownleaves1 from '../../public/static/img/kew/brownleaves1.jpg'
import brownleaves2 from '../../public/static/img/kew/brownleaves2.jpg'
import brownleaves3 from '../../public/static/img/kew/brownleaves3.jpg'
import dandelion1 from '../../public/static/img/kew/dandelion1.jpg'
import fallenleaves1 from '../../public/static/img/kew/fallenleaves1.jpg'
import fallenleaves2 from '../../public/static/img/kew/fallenleaves2.jpg'
import fallenleaves3 from '../../public/static/img/kew/fallenleaves3.jpg'
import fallenleaves4 from '../../public/static/img/kew/fallenleaves4.jpg'
import fountain1 from '../../public/static/img/kew/fountain1.jpg'
import goose1 from '../../public/static/img/kew/goose1.jpg'
import greenbrownleaves1 from '../../public/static/img/kew/greenbrownleaves1.jpg'
import greenbrownleaves2 from '../../public/static/img/kew/greenbrownleaves2.jpg'
import greenbrownleaves3 from '../../public/static/img/kew/greenbrownleaves3.jpg'
import greenleaf1 from '../../public/static/img/kew/greenleaf1.jpg'
import greenleaf2 from '../../public/static/img/kew/greenleaf2.jpg'
import greenleaf3 from '../../public/static/img/kew/greenleaf3.jpg'
import greenleaves1 from '../../public/static/img/kew/greenleaves1.jpg'
import greenleaves2 from '../../public/static/img/kew/greenleaves2.jpg'
import greenleaves3 from '../../public/static/img/kew/greenleaves3.jpg'
import greenyellowleaves1 from '../../public/static/img/kew/greenyellowleaves1.jpg'
import greenyellowleaves2 from '../../public/static/img/kew/greenyellowleaves2.jpg'
import greenyellowleaves3 from '../../public/static/img/kew/greenyellowleaves3.jpg'
import greenyellowleaves4 from '../../public/static/img/kew/greenyellowleaves4.jpg'
import holly1 from '../../public/static/img/kew/holly1.jpg'
import holly2 from '../../public/static/img/kew/holly2.jpg'
import holly3 from '../../public/static/img/kew/holly3.jpg'
import holly4 from '../../public/static/img/kew/holly4.jpg'
import holly5 from '../../public/static/img/kew/holly5.jpg'
import holly6 from '../../public/static/img/kew/holly6.jpg'
import lake1 from '../../public/static/img/kew/lake1.jpg'
import lake2 from '../../public/static/img/kew/lake2.jpg'
import orangeleaves1 from '../../public/static/img/kew/orangeleaves1.jpg'
import pagoda1 from '../../public/static/img/kew/pagoda1.jpg'
import peacock1 from '../../public/static/img/kew/peacock1.jpg'
import pinkflower1 from '../../public/static/img/kew/pinkflower1.jpg'
import pinkflowers1 from '../../public/static/img/kew/pinkflowers1.jpg'
import pinkflowers2 from '../../public/static/img/kew/pinkflowers2.jpg'
import purpleflower1 from '../../public/static/img/kew/purpleflower1.jpg'
import purpleflower2 from '../../public/static/img/kew/purpleflower2.jpg'
import purpleflower3 from '../../public/static/img/kew/purpleflower3.jpg'
import purpleflower4 from '../../public/static/img/kew/purpleflower4.jpg'
import purpleflower5 from '../../public/static/img/kew/purpleflower5.jpg'
import purpleflower6 from '../../public/static/img/kew/purpleflower6.jpg'
import purpleflowers1 from '../../public/static/img/kew/purpleflowers1.jpg'
import purpleflowers2 from '../../public/static/img/kew/purpleflowers2.jpg'
import redflower1 from '../../public/static/img/kew/redflower1.jpg'
import redgreenleaf1 from '../../public/static/img/kew/redgreenleaf1.jpg'
import redgreenleaves1 from '../../public/static/img/kew/redgreenleaves1.jpg'
import redgreenleaves2 from '../../public/static/img/kew/redgreenleaves2.jpg'
import redgreenleaves3 from '../../public/static/img/kew/redgreenleaves3.jpg'
import redgreenleaves4 from '../../public/static/img/kew/redgreenleaves4.jpg'
import redgreenleaves5 from '../../public/static/img/kew/redgreenleaves5.jpg'
import redgreenleaves6 from '../../public/static/img/kew/redgreenleaves6.jpg'
import redgreenleaves7 from '../../public/static/img/kew/redgreenleaves7.jpg'
import redgreenleaves8 from '../../public/static/img/kew/redgreenleaves8.jpg'
import redgreenleaves9 from '../../public/static/img/kew/redgreenleaves9.jpg'
import redgreenleaves10 from '../../public/static/img/kew/redgreenleaves10.jpg'
import redgreenleaves11 from '../../public/static/img/kew/redgreenleaves11.jpg'
import redgreenleaves12 from '../../public/static/img/kew/redgreenleaves12.jpg'
import redgreenleaves13 from '../../public/static/img/kew/redgreenleaves13.jpg'
import redgreenleaves14 from '../../public/static/img/kew/redgreenleaves14.jpg'
import redgreenleaves15 from '../../public/static/img/kew/redgreenleaves15.jpg'
import redgreenleaves16 from '../../public/static/img/kew/redgreenleaves16.jpg'
import redleaf1 from '../../public/static/img/kew/redleaf1.jpg'
import redleaf2 from '../../public/static/img/kew/redleaf2.jpg'
import redleaves1 from '../../public/static/img/kew/redleaves1.jpg'
import redleaves2 from '../../public/static/img/kew/redleaves2.jpg'
import redleaves3 from '../../public/static/img/kew/redleaves3.jpg'
import redleaves4 from '../../public/static/img/kew/redleaves4.jpg'
import redleaves5 from '../../public/static/img/kew/redleaves5.jpg'
import redleaves6 from '../../public/static/img/kew/redleaves6.jpg'
import redleaves7 from '../../public/static/img/kew/redleaves7.jpg'
import redleaves8 from '../../public/static/img/kew/redleaves8.jpg'
import redleaves9 from '../../public/static/img/kew/redleaves9.jpg'
import redleaves10 from '../../public/static/img/kew/redleaves10.jpg'
import redyellowleaves1 from '../../public/static/img/kew/redyellowleaves1.jpg'
import redyellowleaves2 from '../../public/static/img/kew/redyellowleaves2.jpg'
import redyellowleaves3 from '../../public/static/img/kew/redyellowleaves3.jpg'
import redyellowleaves4 from '../../public/static/img/kew/redyellowleaves4.jpg'
import redyellowleaves5 from '../../public/static/img/kew/redyellowleaves5.jpg'
import redyellowleaves6 from '../../public/static/img/kew/redyellowleaves6.jpg'
import redyellowleaves7 from '../../public/static/img/kew/redyellowleaves7.jpg'
import redyellowleaves8 from '../../public/static/img/kew/redyellowleaves8.jpg'
import rosehips1 from '../../public/static/img/kew/rosehips1.jpg'
import sky1 from '../../public/static/img/kew/sky1.jpg'
import squirrel1 from '../../public/static/img/kew/squirrel1.jpg'
import squirrel2 from '../../public/static/img/kew/squirrel2.jpg'
import squirrel3 from '../../public/static/img/kew/squirrel3.jpg'
import squirrel4 from '../../public/static/img/kew/squirrel4.jpg'
import squirrel5 from '../../public/static/img/kew/squirrel5.jpg'
import temple1 from '../../public/static/img/kew/temple1.jpg'
import temple2 from '../../public/static/img/kew/temple2.jpg'
import tree1 from '../../public/static/img/kew/tree1.jpg'
import tree2 from '../../public/static/img/kew/tree2.jpg'
import tree3 from '../../public/static/img/kew/tree3.jpg'
import whiteflowers1 from '../../public/static/img/kew/whiteflowers1.jpg'
import whiteflowers2 from '../../public/static/img/kew/whiteflowers2.jpg'
import yellowleaf1 from '../../public/static/img/kew/yellowleaf1.jpg'
import yellowleaves1 from '../../public/static/img/kew/yellowleaves1.jpg'
import yellowleaves2 from '../../public/static/img/kew/yellowleaves2.jpg'

export const imagesKew = {
  avenue1,
  berries1,
  berries2,
  bridge1,
  brownleaf1,
  brownleaves1,
  brownleaves2,
  brownleaves3,
  dandelion1,
  fallenleaves1,
  fallenleaves2,
  fallenleaves3,
  fallenleaves4,
  fountain1,
  goose1,
  greenbrownleaves1,
  greenbrownleaves2,
  greenbrownleaves3,
  greenleaf1,
  greenleaf2,
  greenleaf3,
  greenleaves1,
  greenleaves2,
  greenleaves3,
  greenyellowleaves1,
  greenyellowleaves2,
  greenyellowleaves3,
  greenyellowleaves4,
  holly1,
  holly2,
  holly3,
  holly4,
  holly5,
  holly6,
  lake1,
  lake2,
  orangeleaves1,
  pagoda1,
  peacock1,
  pinkflower1,
  pinkflowers1,
  pinkflowers2,
  purpleflower1,
  purpleflower2,
  purpleflower3,
  purpleflower4,
  purpleflower5,
  purpleflower6,
  purpleflowers1,
  purpleflowers2,
  redflower1,
  redgreenleaf1,
  redgreenleaves1,
  redgreenleaves2,
  redgreenleaves3,
  redgreenleaves4,
  redgreenleaves5,
  redgreenleaves6,
  redgreenleaves7,
  redgreenleaves8,
  redgreenleaves9,
  redgreenleaves10,
  redgreenleaves11,
  redgreenleaves12,
  redgreenleaves13,
  redgreenleaves14,
  redgreenleaves15,
  redgreenleaves16,
  redleaf1,
  redleaf2,
  redleaves1,
  redleaves2,
  redleaves3,
  redleaves4,
  redleaves5,
  redleaves6,
  redleaves7,
  redleaves8,
  redleaves9,
  redleaves10,
  redyellowleaves1,
  redyellowleaves2,
  redyellowleaves3,
  redyellowleaves4,
  redyellowleaves5,
  redyellowleaves6,
  redyellowleaves7,
  redyellowleaves8,
  rosehips1,
  sky1,
  squirrel1,
  squirrel2,
  squirrel3,
  squirrel4,
  squirrel5,
  temple1,
  temple2,
  tree1,
  tree2,
  tree3,
  whiteflowers1,
  whiteflowers2,
  yellowleaf1,
  yellowleaves1,
  yellowleaves2
}